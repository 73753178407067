import request from "@/utils/request";

// 创建用户组
export function createUserGroup(data) {
  return request({
    url: "/bpm/user-group/create",
    method: "post",
    data: data,
  });
}

// 更新用户组
export function updateUserGroup(data) {
  return request({
    url: "/bpm/user-group/update",
    method: "put",
    data: data,
  });
}

// 删除用户组
export function deleteUserGroup(id) {
  return request({
    url: "/bpm/user-group/delete?id=" + id,
    method: "delete",
  });
}

// 获得用户组
export function getUserGroup(id) {
  return request({
    url: "/bpm/user-group/get?id=" + id,
    method: "get",
  });
}

// 获得用户组分页
export function getUserGroupPage(query) {
  return request({
    url: "/bpm/user-group/page",
    method: "get",
    params: query,
  });
}

// 获取用户组精简信息列表
export function listSimpleUserGroups() {
  return request({
    url: "/bpm/user-group/list-all-simple",
    method: "get",
  });
}

// 添加用户组(五期)
export function addUserGroup(data) {
  return request({
    url: "/bpm/user-group/addGroup",
    method: "post",
    data,
  });
}
// 编辑用户组(五期)
export function editUserGroup(data) {
  return request({
    url: "/bpm/user-group/editGroup",
    method: "post",
    data,
  });
}
// 删除用户组(五期)
export function deleteGroup(params) {
  return request({
    url: "/bpm/user-group/deleteGroup",
    method: "delete",
    params,
  });
}

// 查询用户组(五期)
export function findGroupsList(params) {
  return request({
    url: "/bpm/user-group/findGroupsList",
    method: "get",
    params,
  });
}
// 查询成员(五期)
export function findMembers(params) {
  return request({
    url: "/bpm/user-group/findMembers",
    method: "get",
    params,
  });
}

// 查询用户组关联流程(五期)
export function findRelationFlow(params) {
  return request({
    url: "/bpm/user-group/findRelationFlow",
    method: "get",
    params,
  });
}

// 查询用户组关联流程(五期)
export function deleteMembers(data) {
  return request({
    url: "/bpm/user-group/deleteMembers",
    method: "post",
    data,
  });
}

// 设置成员管理范围(五期)
export function setUserScope(data) {
  return request({
    url: "/bpm/user-group/setUserScope",
    method: "post",
    data,
  });
}

// 新增成员(五期)
export function addMembers(data) {
  return request({
    url: "/bpm/user-group/addMembers",
    method: "post",
    data,
  });
}

// 导出(七期)
export function takExport(data) {
  return request({
    url: "/bpm/user-group/export",
    method: "post",
    responseType:"arraybuffer"
  });
}
