// 处理url的逻辑

import request from "@/utils/request";
import { getRelationApprovalByC } from "@/api/bpm/form";
import store from '@/store';
import { cloneDeep } from 'lodash-es';
import { createLogger } from "vuex";


// 关联审批数据value值改为一致
function formatRelationApprovalData(data) {
  function approvalData(arr, fatherLevel, isParent = true) {
    if (isParent) {
      arr.value = arr.value + '-' + fatherLevel
    }
    if (arr.children) {
      arr.children.forEach((item) => {
        approvalData(item, fatherLevel)
      })
    }
  };
  data?.forEach((itme) => {
    approvalData(itme, itme.value, false)
  });

  return data || [];

}

export const fetchData = (component, formId, userId, defaultValue) => {
  const { dataType, method, url, type } = component.__config__;
  let param = {
    method: method,
    url: url,
  };
  if (dataType === "dynamic" && method && url) {
    request(param).then((resp) => {
      const { type, selectType } = component.__config__;
      switch (type) {
        case "department": //部门
          component.options = resp.data[0].children;
          break;
        case "position": //职位
          const positionData = resp.data.map((item) => {
            return {
              label: item.postName,
              value: item.postId,
            };
          });
          component.__slot__.options = positionData;
          break;
        case "rank": //职级
          const rankData = resp.data.map((item) => {
            return {
              label: item.rankName,
              value: item.rankId,
            };
          });
          component.__slot__.options = rankData;
          break;
        case "contacts": //联系人
          const contactsData = resp.rows.map((item) => {
            return {
              label: item.userName,
              value: item.userId.toString(),
            };
          });
          component.__slot__.options = contactsData;
          break;
        case "litigant": //实际申请人
          const litigantData = resp.rows.map((item) => {
            return {
              label: item.userName,
              value: item.userId.toString(),
            };
          });
          component.__slot__.options = litigantData;
          break;
        default:
          break;
      }
      switch (selectType) {
        case "select": //下拉选择
          component.__slot__.options = resp.data;
          break;
        case "cascader": //级联选择
          component.options = resp.data;
          break;
        case "radio": //单选框组
          component.__slot__.options = resp.data;
          break;
        case "checkbox": //单选框组
          component.__slot__.options = resp.data;
          break;
        default:
      }
    });
  }
  if (dataType === "approval") {
    const id = JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId
    getRelationApprovalByC(component.__config__.approval, userId ? userId : id).then((resp) => {
      component.options = formatRelationApprovalData(resp.data)
    });
  }
  return component;
};

// 接口返回数据处理
function handleData(resp, component) {
  const { type, selectType } = component.__config__;
  switch (type) {
    case "department": //部门
      component.options = resp[0]?.children;
      break;
    case "position": //职位

      const positionData = resp.map((item) => {
        return {
          label: item.postName,
          value: item.postId,
        };
      });
      component.__slot__.options = positionData;
      break;
    case "rank": //职级

      const rankData = resp.map((item) => {
        return {
          label: item.rankName,
          value: item.rankId,
        };
      });
      component.__slot__.options = rankData;
      break;
    case "contacts": //联系人
      const contactsData = resp.map((item) => {
        return {
          label: item.userName,
          value: item.userId.toString(),
        };
      });
      component.__slot__.options = contactsData;
      break;
    case "litigant": //实际申请人
      const litigantData = resp.map((item) => {
        return {
          label: item.userName,
          value: item.userId.toString(),
        };
      });
      component.__slot__.options = litigantData;
      break;

    default:
      break;
  };

  switch (selectType) {
    case "select": //下拉选择
      component.__slot__.options = resp.data;
      break;
    case "cascader": //级联选择
      component.options = resp.data;
      break;
    case "radio": //单选框组
      component.__slot__.options = resp.data;
      break;
    case "checkbox": //单选框组
      component.__slot__.options = resp.data;
      break;
    default:
  }

  return component;

}

// 发起所有的获取数据接口
function allFetchData(longRangeList) {
  let promises = [];
  longRangeList.forEach(item => {
    const { method, url } = item.__config__;
    let param = {
      method: method,
      url: url,
    };
    switch (item.__config__.type) {
      case 'department':
        promises.push(new Promise((resolve) => {
          resolve(store.state.imWorkbench.department);
        }).then((resp) => handleData(resp, item)))
        break;
      case 'litigant':
      case 'contacts':
        promises.push(new Promise((resolve) => {
          resolve(store.state.imWorkbench.allPersonnel);
        }).then((resp) => handleData(resp, item)))
        break;
      case 'position':
        promises.push(new Promise((resolve) => {
          resolve(store.state.imWorkbench.position);
        }).then((resp) => handleData(resp, item)))
        break;
      case 'rank':
        promises.push(new Promise((resolve) => {
          resolve(store.state.imWorkbench.rank);
        }).then((resp) => handleData(resp, item)))
        break;
      default:
        promises.push(request(param).then((resp) => handleData(resp, item)));
        break;
    }
  });
  return promises;
}

// 同步处理数据
export const AsyncFetchData = async (froms, formId, userId, defaultValue) => {
  const id = JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId
  const fields = cloneDeep(froms);
  const longRangeList = [];
  let relatedApproval = [];
  fields.forEach(item => {
    if (item.__vModel__) {
      if (item.__config__.dataType === "dynamic" && item.__config__.method && item.__config__.url) {
        longRangeList.push(item);
      }
      if (item.__config__.dataType === "approval") {
        relatedApproval.push(getRelationApprovalByC(item.__config__.approval, userId ? userId : id).then((resp) => {
          item.options = formatRelationApprovalData(resp.data)
        }))
      }
    } else if (item.__config__.children) {
      item.__config__.children.forEach(row => {
        row.forEach(rowItem => {
          if (rowItem.__config__.dataType === "dynamic" && rowItem.__config__.method && rowItem.__config__.url) {
            longRangeList.push(rowItem);
          }
        });
      });
    }
  });
  await Promise.all([...allFetchData(longRangeList), ...relatedApproval])
  return fields;
}


// 获取数据
export const initData = () => {
  const token = localStorage.getItem('key_user_token');
  if (token) {
    // 获取部门树
    request({
      url: '/bpm/flowManagement/deptTree',
      method: 'get'
    }).then((err) => {
      store.commit('imWorkbench/setDepartment', err.data)
    }).catch((error) => {
      console.log(error.msg)
    })

    // 获取所有人员
    request({
      url: '/system/bpm/user/list-all-simple',
      method: 'get'
    }).then((err) => {
      store.commit('imWorkbench/setAllpersonnel', err.rows)
    }).catch((error) => {
      console.log(error.msg)
    })

    // 职位
    request({
      url: '/system/bpm/post/list-all-simple',
      method: 'get'
    }).then((err) => {
      store.commit('imWorkbench/setPosition', err.rows)
    }).catch((error) => {
      console.log(error.msg)
    })

    // 职级
    request({
      url: '/system/bpm/rank/list-all-simple',
      method: 'get'
    }).then((err) => {
      store.commit('imWorkbench/setRank', err.rows)
    }).catch((error) => {
      console.log(error.msg)
    })

    // 七牛云上传token
    request({
      url: '/resource/qiniu/getUploadToken/1',
      method: 'get'
    }).then((err) => {
      store.commit('imWorkbench/setUploadToken', err.data)
    }).catch((error) => {
      console.log(error.msg)
    })
  }
}