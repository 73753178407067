<template>
    <div id="navig">
        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" router :default-active="this.$route.path">
            <el-menu-item route>
                <img src="@/assets/ioc/Group_1000005379.png" alt="" @click.stop="aaa">
            </el-menu-item>
            <el-menu-item :index="item.path" v-for="item in routes" :key="item.path">
                <div>
                    <img :src="getImagePath(item.meta.icon)" alt="" style="margin-right: 5px;">
                    <span slot="title">{{ item.meta.title }}</span>
                </div>
            </el-menu-item>

            <el-menu-item class="setUp" style="position: absolute;right: 0px;" v-if="condition">
                <el-button size="medium" style="color: rgb(144, 147, 153);display: flex;" @click.stop="openBackGround">
                    <img src="@/assets/ioc/nut.png" alt="Custom Icon" class=" icon">
                    <span>管理后台</span>
                </el-button>
            </el-menu-item>
        </el-menu>

        <keep-alive>
            <router-view></router-view>
        </keep-alive>

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import routes from '@/router/index'
    import { getRoutersV2 } from "@/api/system/menu";
    export default {
        data() {
            return {
                // activeIndex: '/main/workbench/Workflow/Navigate/home',
                routes: [],
                condition: false
            };
        },
        async mounted() {
            this.routes = await this.getRoutes()
        },
        methods: {
            aaa(){
                location.reload(true);
            },
            // 使用 require 动态解析路径
            getImagePath(iconPath) {
                return require(`@/assets/${iconPath}`);
            },
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            },
            openBackGround() {
                this.txsuggestion()
            },
            async getRoutes() {
                let list = routes[0].children.filter(item => item.hasOwnProperty('meta'))
                const res = await getRoutersV2({ flag: 'workflow' })
                list.forEach((err, index) => {
                    if (err.meta.menuId) {
                        let is = res.data.some(item => item.menuId === err.meta.menuId);
                        if (!is) {
                            list.splice(index, 1);
                        }
                    }
                });
                this.condition = res.data.some(item => item.menuId === '1785157490073968642');
                return list
            }
        }
    }
</script>

<style>
    #navig {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    .btns {
        margin: 100px;
    }

    .btns button {
        margin: 0 10px;
    }

    .icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        margin-left: -8px;
    }
</style>