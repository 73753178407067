var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conetents" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.getList,
            expression: "getList"
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "c-list",
        attrs: {
          "infinite-scroll-delay": "200",
          "infinite-scroll-disabled": "disabled"
        }
      },
      [
        _vm._l(_vm.tableData, function(row, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "c-list-box",
              on: {
                click: function($event) {
                  return _vm.handleClick(row)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "list-box",
                  staticStyle: { width: "18%", "padding-left": "0px" }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { margin: "auto", "max-width": "130px" } },
                    [
                      _c("div", { staticClass: "header-bg" }, [
                        _c("img", {
                          staticStyle: {
                            width: "24px",
                            height: "24px",
                            "border-radius": "50%"
                          },
                          attrs: {
                            src:
                              row.initiator.avatarUrl !== null
                                ? row.initiator.avatarUrl
                                : "https://source.fungsong.com/3451926509266j7jEgm441uQs.png",
                            alt: ""
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "text-cs",
                            staticStyle: {
                              "line-height": "24px",
                              "margin-left": "8px",
                              color: "#5669ec"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openUserDetail(row.initiator.userId)
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(row.initiator.userName) + " "),
                            _c(
                              "span",
                              { staticStyle: { color: "rgba(27, 27, 27, 1)" } },
                              [_vm._v("提交的")]
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "text-cs",
                          staticStyle: {
                            "text-align": "left",
                            width: "130px",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "white-space": "nowrap"
                          }
                        },
                        [_vm._v(" " + _vm._s(row.name) + " ")]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "list-box text-cs",
                  staticStyle: { width: "18%" }
                },
                [
                  row.digest
                    ? _c("div", [
                        row.digest.length && row.digest.length < 4
                          ? _c(
                              "div",
                              _vm._l(row.digest, function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    style:
                                      "text-align: center;width: 100%;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.name) +
                                        ":" +
                                        _vm._s(
                                          item.value == "undefined"
                                            ? ""
                                            : item.value
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              [
                                _vm._l(row.digest, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: index < 3 || row.isShow,
                                          expression: "index < 3 || row.isShow"
                                        }
                                      ],
                                      key: index,
                                      style: row.isShow
                                        ? ""
                                        : "width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.name) +
                                          ":" +
                                          _vm._s(
                                            item.value == "undefined"
                                              ? ""
                                              : item.value
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#1890ff",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        row.isShow = !row.isShow
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(row.isShow ? "收起" : "展开") + " "
                                    )
                                  ]
                                )
                              ],
                              2
                            )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "list-box",
                  staticStyle: { width: "12%", "padding-left": "7px" }
                },
                [
                  row.assigner && row.assigner.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "text-cs",
                            staticStyle: {
                              "line-height": "24px",
                              "margin-left": "8px",
                              color: "#5669ec"
                            }
                          },
                          _vm._l(row.assigner, function(item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openUserDetail(item.userId)
                                  }
                                }
                              },
                              [
                                _vm._v(_vm._s(item.userName) + " "),
                                index < row.assigner.length - 1
                                  ? _c("span", [_vm._v("/")])
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    : [_vm._v("-")]
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "list-box text-cs",
                  staticStyle: { width: "14%" }
                },
                [_vm._v(" " + _vm._s(row.groupName || "暂无") + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "list-box text-cs",
                  staticStyle: { width: "13%" }
                },
                [_c("span", [_vm._v(_vm._s(_vm.parseTime(row.createTime)))])]
              ),
              _c(
                "div",
                {
                  staticClass: "list-box text-cs",
                  staticStyle: { width: "13%" }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.parseTime(row.endTime) || "-"))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "list-box text-cs",
                  staticStyle: { width: "13%" }
                },
                [
                  row.status == 1
                    ? _c("span", { staticStyle: { color: "#5669ec" } }, [
                        _vm._v("进行中")
                      ])
                    : _vm._e(),
                  row.status == 2 && row.result == 2
                    ? _c("span", { staticStyle: { color: "#67c23a" } }, [
                        _vm._v("已通过")
                      ])
                    : _vm._e(),
                  row.status == 2 && row.result == 3
                    ? _c("span", { staticStyle: { color: "#f83e45" } }, [
                        _vm._v("不通过")
                      ])
                    : _vm._e(),
                  row.status == 3
                    ? _c("span", { staticStyle: { color: "#848484" } }, [
                        _vm._v("已撤回")
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        }),
        _vm.tableData.length == 0
          ? _c(
              "div",
              {
                staticStyle: { "text-align": "center", "margin-top": "100px" }
              },
              [
                _c("img", {
                  attrs: {
                    src:
                      "https://source.fungsong.com/3453288888914YKG1W1O2ZTgm.png",
                    alt: ""
                  }
                })
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-header" }, [
      _c("div", { staticClass: "font ", staticStyle: { width: "18%" } }, [
        _vm._v("流程名")
      ]),
      _c("div", { staticClass: "font ", staticStyle: { width: "19%" } }, [
        _vm._v("摘要")
      ]),
      _c("div", { staticClass: "font", staticStyle: { width: "11%" } }, [
        _vm._v("当前审批人")
      ]),
      _c("div", { staticClass: "font", staticStyle: { width: "14%" } }, [
        _vm._v("所属分组")
      ]),
      _c("div", { staticClass: "font", staticStyle: { width: "13%" } }, [
        _vm._v("提交时间")
      ]),
      _c("div", { staticClass: "font", staticStyle: { width: "13%" } }, [
        _vm._v("结束时间")
      ]),
      _c("div", { staticClass: "font", staticStyle: { width: "13%" } }, [
        _vm._v("流程状态")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }