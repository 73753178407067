
const condition = {
  state: {
    showCondition: false,
  },
  mutations: {
    CHANGE_SHOW(state, val) {
      state.showCondition = val
    },
  },
  actions: {

  }
}
export default condition
