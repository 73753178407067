var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticStyle: { "padding-left": "20px", "padding-right": "20px" } },
          [
            _c("header", [
              _c(
                "div",
                { staticClass: "detailed" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                        "white-space": "nowrap"
                      }
                    },
                    [
                      _vm._v(
                        " 审批编号：" + _vm._s(_vm.processInstance.id) + " "
                      )
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "分享",
                        placement: "bottom",
                        "popper-class": "custom-tooltip"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          src: require("@/assets/ioc/arrow-export.png"),
                          alt: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.shareApproval(
                              _vm.processInstance,
                              _vm.currentNodeId
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "user" }, [
                _c("img", {
                  staticClass: "info_img",
                  attrs: {
                    src:
                      _vm.startUser.avatarUrl ||
                      require("@/assets/ioc/Group_41.png"),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "info" }, [
                  _c(
                    "div",
                    { staticClass: "info_top" },
                    [
                      _c("p", { staticStyle: { margin: "0px" } }, [
                        _vm._v(_vm._s(_vm.processInstance.name))
                      ]),
                      _c("dict-tag", {
                        attrs: {
                          type: "bpm_process_instance_result",
                          value: _vm.processInstance.result
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info_bottom" }, [
                    _c("span", [
                      _vm._v(
                        "提交于 " +
                          _vm._s(
                            _vm.formattedTime(_vm.processInstance.createTime)
                          )
                      )
                    ])
                  ])
                ]),
                _c("img", {
                  staticClass: "info_type",
                  attrs: { src: _vm.displayStatus(), alt: "" }
                })
              ])
            ]),
            _c(
              "main",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: !_vm.dataLoaded,
                    expression: "!dataLoaded"
                  }
                ],
                staticClass: "main"
              },
              [
                _c("div", {
                  staticClass: "first_line",
                  staticStyle: { "margin-bottom": "8px" }
                }),
                _c(
                  "div",
                  { staticClass: "info_box" },
                  [
                    this.processInstance.processDefinition &&
                    this.processInstance.processDefinition.formType === 10
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.parserLoading,
                                expression: "parserLoading"
                              }
                            ]
                          },
                          [
                            _vm.dataLoaded
                              ? _c("parser", {
                                  key: _vm.datekey,
                                  ref: "oArser",
                                  attrs: {
                                    "form-conf": _vm.detailForm,
                                    disabled: true,
                                    disabledReset: true,
                                    submitDisabled: _vm.submitDisabled,
                                    candidate_dept: _vm.candidate_dept
                                  },
                                  on: {
                                    submit: _vm.submit,
                                    onPreview: _vm.onPreviewFile,
                                    changefiles: _vm.changefiles,
                                    refresh: _vm.initial
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    this.processInstance.processDefinition &&
                    this.processInstance.processDefinition.formType === 20
                      ? _c(
                          "div",
                          [
                            _c("async-biz-form-component", {
                              attrs: { id: this.processInstance.businessKey }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.detailForm.fields, function(item, index) {
                      return _c("div", { key: index })
                    })
                  ],
                  2
                ),
                _c("div", {
                  staticClass: "first_line",
                  staticStyle: { "margin-top": "4px" }
                }),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("div", { staticClass: "right_top" }, [_vm._v("审批")]),
                    _c("timeline", {
                      attrs: { tasks: _vm.tasks, userId: _vm.userId },
                      on: { deleteComment: _vm.deleteComment }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c("div", { staticClass: "footer", attrs: { id: "btnDiv" } }, [
          _vm.processInstance.status == 1 || _vm.processInstance.status == 2
            ? _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.openEvaluateDialog(_vm.processInstance.status)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/ioc/chat.png"), alt: "" }
                  }),
                  _c("div", { staticClass: "footer-text" }, [_vm._v("评论")])
                ]
              )
            : _vm._e(),
          _vm.chexiaoBtn
            ? _c("div", { on: { click: _vm.handleCancel } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/ioc/rotate-left.png"),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "footer-text" }, [_vm._v("撤销")])
              ])
            : _vm._e(),
          _vm.reStartBtn
            ? _c("div", { on: { click: _vm.reStartGo } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/ioc/arrow-up-right.png"),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "footer-text" }, [_vm._v("重新发起")])
              ])
            : _vm._e(),
          !_vm.scopeType && _vm.caozuoBtn
            ? _c("div", { on: { click: _vm.handleUpdateAssignee } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/ioc/arrows-repeat.png"),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "footer-text" }, [_vm._v("转办")])
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-dropdown",
                {
                  attrs: { "render-to-body": false, trigger: "click" },
                  on: { command: _vm.handleCommand }
                },
                [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/ioc/more-horizontal.png"),
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "footer-text" }, [_vm._v("更多")])
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      attrs: { slot: "dropdown", "append-to-body": false },
                      slot: "dropdown"
                    },
                    [
                      _vm.processInstance.status == 2
                        ? _c("el-dropdown-item", { attrs: { command: 1 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/ioc/send.png"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [_vm._v("抄送")]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      !_vm.scopeType && _vm.jiaqianBtn && _vm.caozuoBtn
                        ? _c("el-dropdown-item", { attrs: { command: 2 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/ioc/plus-circle.png"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [_vm._v("加签")]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      !_vm.scopeType && _vm.caozuoBtn
                        ? _c("el-dropdown-item", { attrs: { command: 3 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/ioc/arrow-back.png"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [_vm._v("回退")]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.urgeProcessingBut
                        ? _c("el-dropdown-item", { attrs: { command: 5 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center"
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "16px",
                                    height: "16px"
                                  },
                                  attrs: {
                                    src: require("@/assets/ioc/bell@2x.png"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [_vm._v("催办")]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c("el-dropdown-item", { attrs: { command: 4 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "16px", height: "16px" },
                              attrs: {
                                src: require("@/assets/ioc/download@2x.png"),
                                alt: ""
                              }
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "8px" } },
                              [_vm._v("下载")]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.caozuoBtn
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "footer-notApproved",
                    on: {
                      click: function($event) {
                        return _vm.handleAudit(false)
                      }
                    }
                  },
                  [_vm._v(" 不通过 ")]
                )
              ])
            : _vm._e(),
          _vm.caozuoBtn
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "footer-adopt",
                    on: {
                      click: function($event) {
                        return _vm.handleAudit(true)
                      }
                    }
                  },
                  [_vm._v(" 通过 ")]
                )
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "转办审批人",
            visible: _vm.updateAssignee.open,
            width: "368px",
            "modal-append-to-body": false,
            "custom-class": "handover-dialog"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.updateAssignee, "open", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "updateAssigneeForm",
              attrs: {
                model: _vm.updateAssignee.form,
                rules: _vm.updateAssignee.rules,
                "label-width": "110px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新审批人", prop: "assigneeUserId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        "popper-append-to-body": false
                      },
                      model: {
                        value: _vm.updateAssignee.form.assigneeUserId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.updateAssignee.form,
                            "assigneeUserId",
                            $$v
                          )
                        },
                        expression: "updateAssignee.form.assigneeUserId"
                      }
                    },
                    _vm._l(_vm.userOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.userName, value: item.userId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "转办原因", prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入转办原因" },
                    model: {
                      value: _vm.updateAssignee.form.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.updateAssignee.form, "reason", $$v)
                      },
                      expression: "updateAssignee.form.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn",
                  on: { click: _vm.cancelUpdateAssigneeForm }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-determine",
                  on: { click: _vm.submitUpdateAssigneeForm }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "取消流程",
            visible: _vm.revokeShow,
            width: "368px",
            "modal-append-to-body": false,
            "custom-class": "handover-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.revokeShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "revoke",
              attrs: {
                model: _vm.revoke,
                rules: _vm.revokeRules,
                "label-width": "110px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "取消理由", prop: "reasonForRevocation" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入取消理由" },
                    model: {
                      value: _vm.revoke.reasonForRevocation,
                      callback: function($$v) {
                        _vm.$set(_vm.revoke, "reasonForRevocation", $$v)
                      },
                      expression: "revoke.reasonForRevocation"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn",
                  on: {
                    click: function($event) {
                      _vm.revokeShow = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-determine",
                  on: { click: _vm.confirmRevocation }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "委派审批人",
            visible: _vm.updateDelegate.open,
            width: "500px",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.updateDelegate, "open", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "updateDelegateForm",
              attrs: {
                model: _vm.updateDelegate.form,
                rules: _vm.updateDelegate.rules,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新审批人", prop: "delegateUserId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", clearable: "" },
                      model: {
                        value: _vm.updateDelegate.form.delegateUserId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.updateDelegate.form,
                            "delegateUserId",
                            $$v
                          )
                        },
                        expression: "updateDelegate.form.delegateUserId"
                      }
                    },
                    _vm._l(_vm.userOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.userName, value: item.userId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "委派理由", prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入委派理由" },
                    model: {
                      value: _vm.updateDelegate.form.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.updateDelegate.form, "reason", $$v)
                      },
                      expression: "updateDelegate.form.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitUpdateDelegateForm }
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancelUpdateDelegateForm } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退回流程",
            visible: _vm.returnOpen,
            width: "368px",
            "modal-append-to-body": false,
            "custom-class": "handover-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.returnOpen = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "formRef",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRules,
                "label-width": "110px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退回节点", prop: "targetDefinitionKey" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", "popper-append-to-body": false },
                      model: {
                        value: _vm.formData.targetDefinitionKey,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "targetDefinitionKey", $$v)
                        },
                        expression: "formData.targetDefinitionKey"
                      }
                    },
                    _vm._l(_vm.returnList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.definitionKey }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "回退理由", prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入回退理由" },
                    model: {
                      value: _vm.formData.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "reason", $$v)
                      },
                      expression: "formData.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn",
                  on: {
                    click: function($event) {
                      _vm.returnOpen = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-determine",
                  on: { click: _vm.submitReturn }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "催办",
            visible: _vm.urgeProcessing,
            width: "368px",
            "modal-append-to-body": false,
            "custom-class": "handover-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.urgeProcessing = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "urgeProcessing" },
            [
              _c("div", { staticClass: "text-left urgeProcessing-title" }, [
                _vm._v(" " + _vm._s(_vm.processInstance.name) + " ")
              ]),
              _c("div", { staticClass: "text-left urgeProcessing-time" }, [
                _vm._v(
                  " 提交于" +
                    _vm._s(_vm.formattedTime(_vm.processInstance.createTime)) +
                    " "
                )
              ]),
              _c("div", {
                staticClass: "first_line",
                staticStyle: { height: "0px" }
              }),
              _c("div", { staticClass: "text-left urgeProcessing-content" }, [
                _vm._v(" 发送给 ")
              ]),
              _c(
                "div",
                { staticClass: "urgeProcessing-userList" },
                _vm._l(_vm.currentNode.assigneeUsers, function(item) {
                  return _c(
                    "div",
                    { key: item.userId, staticClass: "urgeProcessing-user" },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            item.avatarUrl ||
                            require("@/assets/ioc/Group_41.png"),
                          alt: ""
                        }
                      }),
                      _c("div", [_vm._v(_vm._s(item.userName))])
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "text-left urgeProcessing-remarks" }, [
                _vm._v("备注")
              ]),
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入" },
                model: {
                  value: _vm.urgeProcessing_remarks,
                  callback: function($$v) {
                    _vm.urgeProcessing_remarks = $$v
                  },
                  expression: "urgeProcessing_remarks"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn",
                  on: {
                    click: function($event) {
                      _vm.urgeProcessing = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer-btn-determine",
                  staticStyle: { width: "100%" },
                  on: { click: _vm.OnUrgeProcessing }
                },
                [_vm._v(" 确定 ")]
              )
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "变更记录",
            visible: _vm.isShowUpdateLog,
            width: "40%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowUpdateLog = $event
            }
          }
        },
        [
          _c(
            "el-timeline",
            { attrs: { reverse: false } },
            _vm._l(_vm.logList, function(move, index) {
              return _c(
                "el-timeline-item",
                {
                  key: index,
                  attrs: {
                    timestamp: new Date(move.updateTime).toLocaleString(),
                    placement: "top"
                  }
                },
                [
                  _c("div", { staticClass: "log-item" }, [
                    _vm._v(_vm._s(move.description))
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c("addSign", {
        ref: "addSign",
        attrs: {
          userOptions: _vm.userOptions,
          taskId: _vm.taskId,
          currentTaskId: _vm.currentTaskId,
          processInstance: _vm.processInstance,
          detailForm: _vm.detailForm,
          candidate_dept: _vm.candidate_dept,
          nodesInfo: _vm.nodesInfo
        },
        on: { addSuccess: _vm.addSuccess }
      }),
      _c("evaluate", {
        ref: "evaluate",
        attrs: {
          width: "60%",
          taskId: _vm.taskId,
          currentTaskId: _vm.currentTaskId,
          id: _vm.id,
          tasks: _vm.tasks,
          modelId: _vm.modelId,
          InstanceData: _vm.InstanceData
        },
        on: { createComment: _vm.createComment }
      }),
      _c("audit", {
        ref: "audit",
        attrs: {
          width: "60%",
          taskId: _vm.taskId,
          processInstance: _vm.processInstance,
          detailForm: _vm.detailForm,
          candidate_dept: _vm.candidate_dept,
          nodesInfo: _vm.nodesInfo
        },
        on: { auditSucceed: _vm.goBack }
      }),
      _c("FilePreviewDialog", {
        attrs: {
          "file-url": _vm.fileUrl,
          fileName: _vm.fileName,
          "dialog-visible": _vm.openFilePreview
        },
        on: { closePreviewDialog: _vm.closePreviewDialog }
      }),
      _c("initiatingRange", {
        ref: "initiatingRange",
        attrs: { title: "添加抄送人", isUser: true, addType: "0" },
        on: { confirm: _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }