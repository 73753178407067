var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c("el-col", { staticClass: "sidebar", attrs: { span: 4 } }, [
        _c(
          "div",
          {
            staticClass: "title",
            staticStyle: { display: "flex", "align-items": "center" }
          },
          [_vm._v("全部审批 ")]
        ),
        _c(
          "div",
          {
            staticClass: "category-list",
            on: {
              mouseenter: function($event) {
                _vm.isManual = true
              },
              mouseleave: function($event) {
                _vm.isManual = false
              }
            }
          },
          _vm._l(_vm.classificationData, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "category",
                class: _vm.activeMenu == item.id ? "select" : "",
                on: {
                  click: function($event) {
                    return _vm.handleSelect(item.id)
                  }
                }
              },
              [
                _c("span", { staticClass: "list-item" }, [
                  _vm._v(_vm._s(item.name))
                ])
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "el-col",
        {
          ref: "scrollContainer",
          staticClass: "approval-content",
          attrs: { span: 20 },
          on: { scroll: _vm.handleScroll }
        },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: { placeholder: "请输入流程名称" },
            on: { input: _vm.getClassificationData },
            scopedSlots: _vm._u([
              {
                key: "prefix",
                fn: function() {
                  return [
                    _c("img", {
                      staticClass: "input-icon",
                      attrs: {
                        src: require("@/assets/ioc/Group_1000005290.png"),
                        alt: "custom icon"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.searchValue,
              callback: function($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue"
            }
          }),
          _vm._l(_vm.classificationData, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                ref: item.id,
                refInFor: true,
                staticClass: "section"
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _c(
                  "div",
                  { staticClass: "approval-list" },
                  _vm._l(_vm.List[item.name], function(approval) {
                    return _c(
                      "div",
                      {
                        key: approval.id,
                        staticClass: "approval-item",
                        on: {
                          click: function($event) {
                            return _vm.openForm(approval)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "approval-icon" }, [
                          _c("img", {
                            attrs: {
                              src:
                                approval.icon ==
                                "https://cdn.fengwork.com/sosee_oa/20240119icon.png"
                                  ? "https://cdn.fengwork.com/workbench/Group_1000005531.png"
                                  : approval.icon,
                              alt: "图标"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "approval-name" }, [
                          _vm._v(_vm._s(approval.name))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }