import "./public-path";
import Vue from "vue";
import App from "./App.vue";
import routes from "./router";
import permission from "./router/permission.js";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import VueRouter from "vue-router";
import plugins from "./plugins"; // plugins
// 字典标签组件
import DictTag from "@/components/DictTag";
import actions from "./actions";
import axios from "axios";
import { initData } from "@/utils/utils.js";
import {
  DICT_TYPE,
  getDictDataLabel,
  getDictDatas,
  getDictDatas2
} from "@/utils/dict";
import {
  parseTime,
  resetForm,
  handleTree,
  addBeginAndEndTime,
  divide
} from "@/utils/ruoyi";
import Detail from "@/views/detail/index";
import request from "@/utils/request";

// 全局方法挂载
// Vue.prototype.getDicts = getDicts;
// Vue.prototype.getConfigKey = getConfigKey;
// Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.getDictDatas = getDictDatas;
Vue.prototype.getDictDatas2 = getDictDatas2;
Vue.prototype.getDictDataLabel = getDictDataLabel;
Vue.prototype.DICT_TYPE = DICT_TYPE;
// Vue.prototype.handleTree = handleTree;
// Vue.prototype.addBeginAndEndTime = addBeginAndEndTime;
// Vue.prototype.divide = divide;
// Vue.config.productionTip = false

// 全局组件挂载
Vue.component("DictTag", DictTag);
Vue.use(plugins);
Vue.use(Element, { size: "small", zIndex: 3000, appendToBody: false });
Vue.prototype.$ELEMENT = {
  // 这里可以自定义挂载点
  appendToBody: false,
  mountElement: document.getElementById("custom-mount-point")
};

let instance = null;
const originFn = document.body.appendChild.bind(document.body);

const router = new VueRouter({
  routes
});

permission(router);

function render(props = {}) {
  const { container, routerBase } = props;
  if (props) {
    actions.setActions(props);
  }

  Vue.prototype.downloadFile = props.downloadFile;
  Vue.prototype.txsuggestion = props.txsuggestion;
  Vue.prototype.openUserCard = props.openUserCard;
  Vue.prototype.shareApproval = props.shareApproval;
  Vue.prototype.callfn = props.callfn;

  redirectPopup(props);

  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(container ? container.querySelector("#app") : "#app");
}

// 初始化数据
// function initData() {
//   const token = localStorage.getItem('key_user_token');
//   if (token) {
//     // 获取部门树
//     request({
//       url: '/bpm/flowManagement/deptTree',
//       method: 'get'
//     }).then((err) => {
//       store.commit('imWorkbench/setDepartment', err.data)
//     }).catch((error) => {
//       console.log(error.msg)
//     })

//     // 获取所有人员
//     request({
//       url: '/system/bpm/user/list-all-simple',
//       method: 'get'
//     }).then((err) => {
//       store.commit('imWorkbench/setAllpersonnel', err.rows)
//     }).catch((error) => {
//       console.log(error.msg)
//     })

//     // 职位
//     request({
//       url: '/system/bpm/post/list-all-simple',
//       method: 'get'
//     }).then((err) => {
//       store.commit('imWorkbench/setPosition', err.rows)
//     }).catch((error) => {
//       console.log(error.msg)
//     })

//     // 职级
//     request({
//       url: '/system/bpm/rank/list-all-simple',
//       method: 'get'
//     }).then((err) => {
//       store.commit('imWorkbench/setRank', err.rows)
//     }).catch((error) => {
//       console.log(error.msg)
//     })

//     // 七牛云上传token
//     request({
//       url: '/resource/qiniu/getUploadToken/1',
//       method: 'get'
//     }).then((err) => {
//       store.commit('imWorkbench/setUploadToken', err.data)
//     }).catch((error) => {
//       console.log(error.msg)
//     })
//   }
// }

if (!window.__POWERED_BY_QIANKUN__) {
  // 这里是子应用独立运行的环境，实现子应用的登录逻辑

  // 独立运行时，也注册一个名为global的store module
  // commonStore.globalRegister(store)
  // // 模拟登录后，存储用户信息到global module
  // const userInfo = { name: '我是独立运行时名字叫张三' } // 假设登录后取到的用户信息
  // store.commit('global/setGlobalState', { user: userInfo })
  initData();
  render();
}

function redirectPopup(container) {
  // 子应用中需要挂载到子应用的弹窗className。样式class白名单，用子应用的样式。
  const whiteList = [
    "el-select-dropdown",
    "el-popper",
    "el-popover",
    "el-dialog__wrapper",
    "el-tooltip"
  ];

  // 保存原有document.body.appendChild方法
  const originFn = document.body.appendChild.bind(document.body);

  // 重写appendChild方法
  document.body.appendChild = dom => {
    // 根据标记，来区分是否用新的挂载方式
    let count = 0;
    whiteList.forEach(x => {
      if (typeof dom.className == "string" && dom.className?.includes(x))
        count++;
    });

    if (count > 0) {
      // 有弹出框的时候，挂载的元素挂载到子应用上，而不是主应用的body上
      document
        .querySelectorAll(".boxbox")
        [document.querySelectorAll(".boxbox")?.length - 1]?.appendChild(dom);
    } else {
      originFn(dom);
    }
  };
}

export async function bootstrap() {
}

export async function mount(props) {
  initData();
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;

  instance?.unmount?.();
  instance = null;
  history?.destroy?.();
  document.body.appendChild = originFn;
}
