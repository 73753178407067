<template>
  <el-dialog title="加签" :visible.sync="dialogVisible" width="60%" :modal-append-to-body="false"
    :custom-class="'addSignature-dialog'">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" @close="close">
      <el-form-item label="加签审批人" prop="userIdList">
        <el-select :popper-append-to-body="false" v-model="ruleForm.userIdList" filterable clearable multiple
          style="width: 100%" placeholder="请选择加签审批人">
          <el-option v-for="(item, index) in userOptions" :key="index" :label="item.userName" :value="item.userId" />
          <!-- <template #default="{ item }">
            <div class="custom-tag">{{ item.label }} (已选)</div>
          </template> -->
        </el-select>
      </el-form-item>
      <el-form-item label="加签方式" prop="type">
        <el-select :popper-append-to-body="false" v-model="ruleForm.type" clearable style="width: 100%"
          placeholder="请选择加签方式">
          <el-option v-for="(item, index) in typeOptions" :key="index" :label="item.label" :value="item.value" />
        </el-select>
        <span class="sign-explain" v-show="ruleForm.type === 'before'">加签后 流程先经过被加签人</span>
        <span class="sign-explain" v-show="ruleForm.type === 'after'">加签后 流程审批后经过被加签人</span>
        <span></span>
      </el-form-item>
      <el-form-item label="审批方式" prop="approveType" v-if="ruleForm.userIdList.length > 1">
        <el-select :popper-append-to-body="false" v-model="ruleForm.approveType" clearable style="width: 100%"
          placeholder="请选择审批方式">
          <el-option v-for="(item, index) in approveTypeOptions" :key="index" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="reason">
        <el-input type="textarea" v-model="ruleForm.reason" placeholder="请填写备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class="dialog-footer">
        <div class="dialog-footer-btn" @click="dialogVisible = false">
          取消
        </div>
        <div class="dialog-footer-btn-determine" @click="fangd('ruleForm')">
          确认加签
        </div>
      </div>
      <!-- <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">确认加签</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import { debounce } from 'lodash-es';
import { createSign } from "@/api/bpm/flow";
export default {
  props: {
    userOptions: {
      type: Array,
      default: [],
    },
    taskId: {
      type: String,
      default: null,
    },
    currentTaskId: {
      type: String,
      default: null,
    },
    processInstance: {
      type: Object,
      default: () => { },
    },
    detailForm: {
      type: Object,
      default: () => { },
    },
    candidate_dept: {
      type: [String, Number],
      default: null,
    },
    nodesInfo: {
        type: Object,
        default: () => {},
      },
  },
  created() {
    // 初始化防抖函数，并确保其可重复使用
    this.debouncedSubmit = debounce(this.submitForm, 500);
  },
  data() {
    return {
      debouncedSubmit: null,
      loading: false,
      dialogVisible: false,
      ruleForm: {
        userIdList: [],
      },
      rules: {
        userIdList: [
          { required: true, message: "请选择加签审批人", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择加签方式", trigger: "blur" }],
        approveType: [
          { required: true, message: "请选择审批方式", trigger: "change" },
        ],
        reason: [{ required: true, message: "请填写备注", trigger: "change" }],
      },
      typeOptions: [
        { value: "before", label: "加前签" },
        { value: "after", label: "加后签" },
      ],
      approveTypeOptions: [
        { value: "and", label: "会签" },
        { value: "or", label: "或签" },
      ],
    };
  },
  methods: {
    close() {
      this.$refs.ruleForm.resetFields();
    },
    fangd(formName) {
      // 使用已经创建的防抖函数
      this.debouncedSubmit(formName);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createSign();
        } else {
          //   console.log("error submit!!");
          return false;
        }
      });
    },
    getVal(item, key) {
      if (item.__config__.tagIcon === "upload") {
        if (typeof item.__config__[key] == 'string') {
          item.__config__[key] = JSON.parse(item.__config__[key])
        }
        let i = item.__config__[key].map(item => item.fileName).join(",")
        return i
      } else if (
        item.__config__.tagIcon === "checkbox" ||
        item.__config__.tagIcon === "date-range" ||
        item.__config__.tagIcon === "time-range"
      ) {
        return item.__config__[key].join(",");
      } else if (item.__config__.tagIcon === "cascader") {
        return item.__config__[key]
          .map((cascaderItem) => {
            const findItem = item.options.find((optionItem) => {
              return cascaderItem === optionItem.value;
            });

            if (findItem) {
              return findItem.label;
            }
          })
          .filter((mapItem) => {
            return mapItem !== undefined;
          })
          .join(",");
      } else {
        return item.__config__[key] + "";
      }
    },
    getRecords(data) {
      // console.log(`output->fields`, this.detailForm.fields)
      return this.detailForm.fields.reduce((acc, item) => {
        // console.log(`output->item`,item)

        if (item.__vModel__) {
          item.__config__.newVal =
            this.processInstance.formVariables[item.__vModel__];
          acc.push({
            processDefinitionId: this.processInstance.processDefinition.id,
            processInstanceId: this.processInstance.id,
            fieldId: item.__vModel__,
            fieldName: item.__config__.label,
            oldVal: this.getVal(item, "defaultValue"),
            newVal: this.getVal(item, "newVal"),
            type: item.__config__.type,
          });
        } else {
          if (item.__config__.children.length > 0) {
            item.__config__.children.forEach((child) => {
              if (Array.isArray(child)) {
                child.forEach((subChild) => {
                  subChild.__config__.newVal =
                    this.processInstance.formVariables[subChild.__vModel__];
                  acc.push({
                    processDefinitionId:
                      this.processInstance.processDefinition.id,
                    processInstanceId: this.processInstance.id,
                    fieldId: subChild.__vModel__,
                    fieldName: subChild.__config__.label,
                    oldVal: this.getVal(subChild, "defaultValue"),
                    newVal: this.getVal(subChild, "newVal"),
                    type: subChild.__config__.type,
                  });
                });
              } else {
                child.__config__.newVal =
                  this.processInstance.formVariables[child.__vModel__];
                acc.push({
                  processDefinitionId:
                    this.processInstance.processDefinition.id,
                  processInstanceId: this.processInstance.id,
                  fieldId: child.__vModel__,
                  fieldName: child.__config__.label,
                  oldVal: this.getVal(child, "defaultValue"),
                  newVal: this.getVal(child, "newVal"),
                  type: child.__config__.type,
                });
              }
            });
          }
        }
        return acc;
      }, []);
    },
    async createSign() {

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let obj = {
        id: this.taskId || this.currentTaskId,
        ...this.ruleForm,
        variables: this.processInstance.formVariables,
        records: this.getRecords(),
      };
      if (this.candidate_dept) {
        obj.variables.candidate_dept = this.candidate_dept;
      }
      let digestsList = obj.records.filter(e => e.tagIcon == 'input')
      let moneyList = obj.records.filter(e => e.type == "amountMoney")
      if (moneyList.length > 0) {
        moneyList.forEach(key => {
          if (obj.variables.hasOwnProperty(key.fieldId)) {
            obj.variables[key.fieldId] = parseFloat(obj.variables[key.fieldId]);
          }
        });
      }
      let digest = []
      if (digestsList.length > 0) {
        digest = digestsList.map(e => {
          return {
            name: e.fieldName,
            value: e.newVal,
          }
        })
      }
      obj.digest = JSON.stringify(digest);
      let nodesInfo = JSON.parse(JSON.stringify(this.nodesInfo))
      if (Object.keys(nodesInfo).length > 0) {
        for (let key in nodesInfo) {
          if (obj.variables.hasOwnProperty(key)) {
            obj.variables[key] = nodesInfo[key];
          }
        }
      }
      const { data } = await createSign(obj);
      this.dialogVisible = false;
      loading.close();
      this.$message.success("加签成功");
      this.$emit("addSuccess");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .addSignature-dialog {

  .el-dialog__header {
    display: flex;

    .el-dialog__headerbtn {
      top: 8px;
      right: 0px;
    }
  }

  .el-dialog__body {
    padding: 20px 20px;

    .el-textarea__inner {
      border: 0px;
      min-height: 100px !important;
    }


    .el-form-item {
      display: grid;
      margin-bottom: 18px !important;

      .el-form-item__label {
        margin-bottom: 12px;
        display: flex;
        justify-content: flex-start;
      }

      .el-form-item__content {
        margin-left: 0px !important;

        .el-input__inner {
          background: #fff !important;
          border: 1px solid rgb(220, 223, 230) !important;
        }
      }
    }
  }

  .el-dialog__footer {
    .dialog-footer {
      display: flex;
      justify-content: flex-end;

      .dialog-footer-btn {
        width: 84px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid rgba(243, 244, 246, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(132, 132, 132, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
      }

      .dialog-footer-btn-determine {
        width: 112px;
        height: 36px;
        border-radius: 4px;
        background: rgba(86, 105, 236, 1);
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.sign-explain {
  color: rgba(86, 105, 236, 1);
  font-family: "Noto Sans SC";
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  margin-left: 2px;
  margin-top: 4px;
}
</style>
