var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.backgroundColor },
    [
      _c("Detail", {
        staticStyle: { "padding-top": "40px" },
        attrs: { drawerData: _vm.drawerData },
        on: { closeDrawer: _vm.closeDrawer, setBackgColor: _vm.setBackgColor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }