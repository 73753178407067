import { render, staticRenderFns } from "./received.vue?vue&type=template&id=13002b11&scoped=true&"
import script from "./received.vue?vue&type=script&lang=js&"
export * from "./received.vue?vue&type=script&lang=js&"
import style0 from "./received.vue?vue&type=style&index=0&id=13002b11&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13002b11",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\CompanyProject\\HF-IM-electron-workbench\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13002b11')) {
      api.createRecord('13002b11', component.options)
    } else {
      api.reload('13002b11', component.options)
    }
    module.hot.accept("./received.vue?vue&type=template&id=13002b11&scoped=true&", function () {
      api.rerender('13002b11', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/conponents/received.vue"
export default component.exports