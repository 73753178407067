
const state = {
    userId: localStorage.getItem("store-user-info") ? JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId : "",
    department: [],
    allPersonnel: [],
    position: [],
    rank: [],
    UploadToken: {},
}


const actions = {

}

const mutations = {
    setDepartment(state, value) {
        state.department = value
    },
    setAllpersonnel(state, value) {
        state.allPersonnel = value
    },
    setPosition(state, value) {
        state.position = value
    },
    setRank(state, value) {
        state.rank = value
    },
    setUploadToken(state, value) {
        state.UploadToken = value
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
