var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "预览",
        visible: _vm.$props.dialogVisible,
        "before-close": _vm.handleClose,
        modal: false,
        "close-on-click-modal": false,
        width: _vm.width,
        "custom-class": "evaluate-dialog"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.$props, "dialogVisible", $event)
        },
        open: _vm.open
      }
    },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "12px", float: "left" },
          attrs: { size: "mini" },
          on: { click: _vm.downLoad }
        },
        [_vm._v("下载")]
      ),
      _vm.getFileType(_vm.fileUrl) == "docx" ||
      _vm.getFileType(_vm.fileUrl) == "doc"
        ? _c(
            "div",
            [
              _c("vue-office-docx", {
                staticStyle: { height: "70vh", overflow: "auto" },
                attrs: { src: _vm.fileUrl },
                on: { rendered: _vm.rendered, error: _vm.errorHandler }
              })
            ],
            1
          )
        : _vm.getFileType(_vm.fileUrl) == "xlsx" ||
          _vm.getFileType(_vm.fileUrl) == "xls"
        ? _c(
            "div",
            { style: { transform: "scale(" + _vm.scale + ")" } },
            [
              _vm.type == "PC"
                ? _c("vue-office-excel", {
                    ref: "excelViewer",
                    staticStyle: { height: "90vh", overflow: "auto" },
                    attrs: { src: _vm.fileUrl },
                    on: { rendered: _vm.rendered, error: _vm.errorHandler }
                  })
                : _vm._e(),
              !(_vm.type == "PC")
                ? _c(
                    "vue-pinch-zoom",
                    { ref: "pinchZoom" },
                    [
                      _c("vue-office-excel", {
                        ref: "excelViewer",
                        staticStyle: { height: "90vh", overflow: "auto" },
                        attrs: { src: _vm.fileUrl },
                        on: { rendered: _vm.rendered, error: _vm.errorHandler }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm.getFileType(_vm.fileUrl) == "pdf"
        ? _c("div", [
            _c("embed", {
              staticStyle: { width: "100%", height: "500px" },
              attrs: { src: _vm.fileUrl }
            })
          ])
        : _vm.getFileType(_vm.fileUrl) == "txt"
        ? _c(
            "div",
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: "", readonly: "" },
                model: {
                  value: _vm.txtContain,
                  callback: function($$v) {
                    _vm.txtContain = $$v
                  },
                  expression: "txtContain"
                }
              })
            ],
            1
          )
        : _vm.getFileType(_vm.fileUrl) == "mp4"
        ? _c("div", [
            _c("video", {
              attrs: { src: _vm.fileUrl, autoplay: "", controls: "" }
            })
          ])
        : _vm.getFileType(_vm.fileUrl) == "jpg" ||
          _vm.getFileType(_vm.fileUrl) == "png" ||
          _vm.getFileType(_vm.fileUrl) == "jpeg"
        ? _c(
            "div",
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.fileUrl }
              })
            ],
            1
          )
        : _c("div", [_c("p", [_vm._v("暂不支持当前格式预览")])])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }