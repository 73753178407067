<template>
  <main>
    <el-empty :description="tip" v-if="!tasks.length"
      image='https://source.fungsong.com/3452097265742FYF1wx7cNHae.png'></el-empty>
    <template v-else>
      <div class="item" v-for="(item, index) in tasks" :key="index">
        <div class="left">
          <div style="display: flex;">
            <img class="user_img" :src="getTaskImg(item)" alt="">
          </div>

          <div class="line" :style="{
            borderLeft: `2px  solid  ${item.result == 2 ? bg2 : item.result == 9999 ? bg3 : bg1
              }
             `,
          }" v-if="index != tasks.length - 1"></div>
        </div>
        <div class="right">
          <div class="descriptions" style="align-items: center;margin-bottom: 10px;">
            <div class="title ">{{ item.result != 9999 ? item.name : '发起了审批' }}</div>
            <div v-if="item.taskType == 'SERVICE_TASK'" class="descriptions-label">抄送人</div>
            <div v-if="item.approveType" class="descriptions-label">{{keytype[item.approveType]}}</div>
            <div v-if="item.assigneeUsers.length > 1" class="descriptions-img"
              @click="showSupplement(item.id || item.taskDefKey)">
              <img :src="getIcon(revoFlag[item.id || item.taskDefKey])" alt="">
            </div>
          </div>
          <!-- 审批人 -->
          <div class="descriptions" v-if="item.assigneeUsers && revoFlag[item.id || item.taskDefKey]">
            <template v-if="item.optionList && item.optionList.length">
              <optionalUser v-if="item.assigneeUsers.length == 0" :taskdata="item.optionList[0]" :users="users"
                :taskDefKey="item.taskDefKey" @chooseUser="chooseUser" />
            </template>
            <div class="value_list" :ref="`featureRef`+index">
              <div class="value scrollBarHidden" v-for="(sub, i) in item.assigneeUsers" :key="i"
                :style="{'margin-top': i>0? '10px' : ''}">
                <img v-if="sub.avatarUrl" :src="sub.avatarUrl" alt="" />
                <img v-else src="@/assets/ioc/Group_41.png" alt="" />
                <img v-if="isHide(item) =='fail' || isHide(item) =='normal'" class="hide_img"
                  :src="getTaskStatusImg(item)" alt="" />
                <p style="white-space: nowrap;color:rgb(27, 27, 27)" class="time"
                  :style="{ 'margin-left': (isHide(item) =='fail' || isHide(item) =='normal') ? '-14px' : '8px' }">
                  {{ sub.userName.indexOf("(节点审批人为空，已转交管理员)") == -1 ? sub.userName : sub.userName.substring(0,
                  sub.userName.indexOf("(节点审批人为空，已转交管理员)")) }}
                </p>
                <el-tooltip class="item" effect="dark" content="节点审批人为空，已转交管理员" placement="top-start"
                  v-if="sub.userName.indexOf('(节点审批人为空，已转交管理员)') != -1">
                  <i class="el-icon-warning"></i>
                </el-tooltip>

                <div class=" supplement">
                  <div v-if="item.result" style="margin-right: 10px;"> {{ keytype[item.result] }}</div>

                  <div v-if="item.endTime">
                    {{ formattedTime(item.endTime) }}
                  </div>
                  <div v-else-if="item.createTime">
                    {{ formattedTime(item.createTime) }}
                  </div>
                </div>



              </div>
            </div>
          </div>

          <!-- 状态 -->
          <!-- <div class="descriptions" v-if="item.result == 10">
            <div class="label">状态</div>
            <div class="value">已转交</div>
          </div> -->
          <!-- 耗时 -->
          <div class="descriptions" v-if="item.result == 1">
            <div class="timeDisplay">{{ diffTime(item.createTime) }}</div>
            <div class="label">已等待</div>
          </div>
          <!-- 备注 -->
          <div class="reason" v-if="item.reason">{{ item.reason }}</div>
          <!-- 评论 -->
          <template v-if="item.comments && item.comments.length">
            <div class="reason" v-for="(e, i) in item.comments" :key="i">
              <div style="margin-bottom: 8px" v-if="e.isWithdraw == 0">
                <div class="name">
                  <p style="margin-bottom: 8px">{{ e.userName }}:</p>
                  <template v-if="userId == e.userId">
                    <el-button type="text" @click="deleteComment(e)" v-if="revocationFlag(e.createTime)">撤销</el-button>
                  </template>
                </div>
                <p>{{ e.comment }}</p>
                <div class="picture_list" v-if="e.picture && e.picture.length">
                  <div v-for="(sub, p) in e.picture" :key="p">
                    <img :src="sub.url" alt="" @click="preview(sub)" style="width: 50px; height: 50px;" />
                  </div>
                </div>
                <div class="attachment_list" v-if="e.attachment && e.attachment.length">
                  <div v-for="(sub, p) in e.attachment" :key="p">
                    <p>{{ sub.name }}</p>
                    <div>
                      <el-link @click="preview(sub)" class="yulan">预览</el-link>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>评论已撤回</div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <!-- 文件预览 -->
    <FilePreviewDialog :file-url="fileUrl" :fileName="fileName" :dialog-visible="openFilePreview"
      @closePreviewDialog="closePreviewDialog" :type="type" :width='(type && type != "PC") ? "90%" : "50%"'>
    </FilePreviewDialog>
  </main>
</template>

<script>
  import FilePreviewDialog from "./FilePreviewDialog.vue";
  import { listSimpleUsers } from "@/api/system/user";
  import optionalUser from "./optionalUser.vue";
  import { deleteComment } from "@/api/bpm/flow";
  import { getDate } from "@/utils/dateUtils";
  import { parseTime } from "@/utils/ruoyi";
  export default {
    components: {
      optionalUser,
      FilePreviewDialog,
    },
    props: ["tasks", "userId", "tip", 'type'],
    data() {
      return {
        users: [],
        bg1: "rgba(228, 229, 231, 1)",
        bg2: "rgba(86, 105, 236, 1)",
        bg3: "rgba(86, 105, 236, 1)",
        openFilePreview: false,
        fileUrl: null, // 预览本地文件，文件放置与public文件夹下
        fileName: null, //
        keytype: {
          'sequence': '依次审批',
          'and': '会签',
          'or': '或签',
          '1': '处理中',
          '2': '通过',
          '3': '不通过',
          '4': '已取消',
          '5': '退回',
          '6': '委派',
          '7': '待后加签任务完成',
          '8': '待前加签任务完成',
          '9': '待前置任务完成',
          '10': '转办',
          '11': '退回至发起人节点',
          '9999': '发起',
        },
        // 按钮判断
        revoFlag: {},
      };
    },
    watch: {
      tasks(val) {
        this.bianlist();
      },
    },
    created() {
      this.listSimpleUsers();
    },
    methods: {
      // 遍历判断是否能隐藏
      bianlist() {
        this.tasks.forEach((item, index) => {
          if (item.assigneeUsers) {
            this.$set(this.revoFlag, [item.id || item.taskDefKey], true);
          }
        });
      },

      // 返回图标
      getIcon(is) {
        if (is) return require(`@/assets/ioc/angle-down-circle.png`);
        return require(`@/assets/ioc/angle-down-ci.png`);
      },

      // 收起节点
      showSupplement(value) {
        this.revoFlag[value] = !this.revoFlag[value];
      },

      // 返回节点图片
      getTaskImg(row) {
        switch (this.isHide(row)) {
          case 'fail':
            return require(`@/assets/ioc/Group_1000005649.png`);
            break;
          case 'abnormal':
            return require(`@/assets/ioc/Group_1000005637.png`);
            break;
          default:
            return require(`@/assets/ioc/Group_1000005636.png`);
            break;
        }
      },

      // 返回节点状态图标
      getTaskStatusImg(row) {
        if (this.isHide(row) == 'fail') {
          return require(`@/assets/ioc/exclircle.png`);
        }
        if (this.isHide(row) == 'normal') {
          return require(`@/assets/ioc/check-circle.png`);
        }
        return ''
      },

      // 判断是否隐藏
      isHide(row) {
        const ID = row.result
        if (ID == 3) {
          return 'fail'
        }
        if (ID == 2 || ID == 6 || ID == 7 || ID == 8 || ID == 9 || ID == 10 || ID == 11 || ID == 9999) {
          return 'normal'
        } else {
          return 'abnormal'
        }
      },

      formattedTime(time) {
        return parseTime(time);
      },

      preview(e) {
        window.ipcRenderer
          .invoke("win-operate", {
            win: "ChildWin",
            operate: "start",
            argu: {
              width: 800,
              height: 550,
              route: `/ImagePreview?src=${e.url}`,
            },
          })
          .then(() => {
            this.$message("预览失效");
          });

      },
      FilePreviewDialogHandle(type, file) {
        this.openFilePreview = !this.openFilePreview;
        this.fileUrl = file.url;
        this.fileName = file.name;
      },
      closePreviewDialog() {
        this.openFilePreview = !this.openFilePreview;
      },
      getFileType(fileUrl) {
        if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
          const fileType = fileUrl.split(".").pop().toLowerCase(); // 文件的扩展名（格式）

          return fileType;
        } else {
          return "docx";
        }
      },
      chooseUser(e) {
        this.$emit("chooseUser", e);
      },
      async listSimpleUsers() {
        const { data } = await listSimpleUsers();
        this.users = data;
      },
      getApproveType(e) {
        if (!e) return;
        let arr = [
          {
            label: "顺签",
            value: "sequence",
          },
          {
            label: "会签",
            value: "and",
          },
          {
            label: "或签",
            value: "or",
          },
        ];
        return arr.find((item) => item.value == e).label;
      },
      async deleteComment(e) {
        let flag = this.revocationFlag(e.createTime);
        if (flag) {
          const { data } = await deleteComment({ commentId: e.id });
          this.$emit("deleteComment");
        } else {
          this.$message("无法撤销");
          this.$emit("deleteComment");
        }
      },
      revocationFlag(e) {
        if (!e) return false;
        let min = 2 * 60 * 1000;
        let createTime = e + min;
        let newTime = new Date().getTime();
        return createTime < newTime ? false : true;
      },
      getDateStar(ms) {
        return getDate(ms);
      },
      diffTime(val) {
        const now = new Date().getTime();
        const future = new Date(val);
        const diff = now - future;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
          return `${days}天`;
        } else if (hours > 0) {
          return `${hours}小时`;
        } else if (minutes > 0) {
          return `${minutes}分钟`;
        } else {
          return `${seconds}秒`;
        }

      },
    },
  };
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .item {
      display: flex;
      gap: 8px;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;


        .user_icon {
          width: 24px;
          height: 24px;
          border: 1px solid rgb(86, 105, 236);
          border-radius: 50%;
          box-sizing: content-box;

          .user_img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }

          .user_img_active {
            position: relative;
            top: -15px;
            left: 10px;
          }
        }


        .dot {
          width: 12px;
          flex: 0 0 12px;
          border-radius: 50%;
          background: "#060000";
        }

        .end_dot {
          width: 12px;
          flex: 0 0 12px;
          border: 1px solid #060000;
          box-sizing: border-box;
          border-radius: 50%;
        }

        .line {
          width: 0;
          flex: 1;
          border-left: 1px dashed #c7c7c9;
        }
      }

      .right {
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        margin-top: 2px;

        .time {
          color: rgba(132, 132, 132, 0.5);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          text-align: left;
        }

        .descriptions {
          display: flex;
          text-align: left;

          /* gap: 10px; */


          .timeDisplay {
            color: rgb(86, 105, 236);
            font-size: 11px;
          }

          .label {
            color: rgb(132, 132, 132);
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-left: 4px;
          }

          .title {
            color: rgb(27, 27, 27);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;
          }

          .value {
            width: 100%;
            /* height: 20px; */
            color: #727272;
            display: flex;
            align-items: center;
            gap: 4px;
            /* margin-bottom: 14px; */

            p {
              flex: 0 0 42px;
            }

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }

          .value_list {
            width: 100%;
            /* display: flex; */
            /* flex-direction: column; */
            /* grid-area: 8px;
            gap: 6px;
            flex-wrap: wrap; */
          }
        }

        .reason {
          width: 100%;
          background: rgba(243, 244, 246, 1);
          border-radius: 0px 6px 6px 6px;
          padding: 8px 12px;
          color: rgba(132, 132, 132, 1);
          text-align: left;
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 11px;
          box-sizing: border-box;

          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .descriptions-label {
    border-radius: 2px;
    border: 1px solid var(--主题色, rgba(86, 105, 236, 1));
    box-sizing: border-box;
    color: var(--主题色, rgba(86, 105, 236, 1));
    font-size: 10px;
    padding: 2px;
    margin-left: 10px;
  }

  .picture_list {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  }

  .attachment_list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >div {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
    }

    .yulan {
      color: rgb(86, 105, 236);
      font-size: 11px;
      font-family: "Noto Sans SC";
      font-weight: 400;
    }
  }

  .scrollBarHidden {
    /* max-width: 180px; */
    /* margin-bottom: 8px; */

    /* 或者 overflow: scroll; */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .scrollBarHidden::-webkit-scrollbar {
    width: 0;
    /* 或者 height: 0; */
    background: transparent;
    /* 使滚动条透明 */
  }

  .user_img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .hide_img {
    width: 16px !important;
    height: 16px !important;
    position: relative;
    top: -12px;
    left: -18px;
  }

  .hide_select {
    border: 1px solid rgba(86, 105, 236, 1)
  }

  .supplement {
    /* white-space: nowrap; */
    margin-left: auto;
    color: rgb(132, 132, 132);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    display: flex;
  }

  .descriptions-img {
    margin-left: auto;
    display: flex;
  }
</style>