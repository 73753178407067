import { listSimpleRoles } from "@/api/system/role";
import { listSimpleUserGroups } from "@/api/bpm/userGroup";
import { listSimpleUsers } from "@/api/system/user";
import { listSimpleDepts } from "@/api/system/dept";
import { handleTree } from "@/utils/ruoyi";
import { getFindDeptUser } from "@/api/bpm/flow.js";
import { findGroupsList } from "@/api/bpm/userGroup";

const state = {
  checkList: [],
  roleOptions: [],
  userGroupOptions: [],
  userOptions: [],
  deptTreeOptions: [],
  depUserList: [],
  groupList: [],
  contact: "", //已经选择联系人
  schemaOption: [
    {
      value: 0,
      label: "自选一个人",
    },
    {
      value: 1,
      label: "自选多个人",
    },
  ],
  scopeOption: [
    {
      value: 0,
      label: "全公司",
    },
    {
      value: 1,
      label: "指定成员",
    },
    {
      value: 2,
      label: "用户组",
    },
  ],
  operatorNumOption: [
    {
      value: "<",
      label: "小于",
    },
    {
      value: ">",
      label: "大于",
    },
    {
      value: "<=",
      label: "小于等于",
    },
    {
      value: "==",
      label: "等于",
    },
    {
      value: ">=",
      label: "大于等于",
    },
    {
      value: "in",
      label: "介于(两个数之间)",
    },
  ],
  extraOption: [
    {
      value: "<",
      label: "<",
    },
    {
      value: "<=",
      label: "≤",
    },
  ],
  operatorOption: [
    { value: "allEqual", label: "完全等于" },
    { value: "partEqual", label: "部分等于" },
  ],
  icon: "https://cdn.fengwork.com/sosee_oa/20240119icon.png",
};

const mutations = {
  ROLES_LIST: (state, device) => {
    state.roleOptions = device;
  },

  GROUP_LIST: (state, device) => {
    state.userGroupOptions = device;
  },
  user_LIST: (state, device) => {
    state.userOptions = device;
  },
  DEPT_TREE: (state, device) => {
    state.deptTreeOptions = [...handleTree(device, "deptId")];
  },
  DEPTUSER_TREE: (state, device) => {
    state.depUserList = device;
  },
  GROUP_TREE: (state, device) => {
    state.groupList = device;
  },
  SET_CONTACT: (state, device) => {
    state.contact = device;
  },
  CHANGE_CHECKLIST: (state, device) => {
    state.checkList = device;
  },
};

const actions = {
  async listSimpleRoles({ commit }, roleName) {
    const { data } = await listSimpleRoles({ roleName });
    commit("ROLES_LIST", data);
  },

  async listSimpleUserGroups({ commit }) {
    const { data } = await listSimpleUserGroups();
    commit("GROUP_LIST", data);
  },
  async listSimpleUsers({ commit }) {
    const { data } = await listSimpleUsers();
    commit("user_LIST", data);
  },
  async listSimpleDepts({ commit }) {
    const { data } = await listSimpleDepts();
    commit("DEPT_TREE", data);
  },
  async listDeptUser({ commit }) {
    const { data } = await getFindDeptUser();
    commit("DEPTUSER_TREE", data);
  },
  async findGroupsList({ commit }) {
    const { data } = await findGroupsList({ name: "" });
    commit("GROUP_TREE", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
