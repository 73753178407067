import request from "@/utils/request";

export function getProcessDefinitionPage(query) {
  return request({
    url: "/bpm/process-definition/page",
    method: "get",
    params: query,
  });
}

export function getProcessDefinitionList(query) {
  return request({
    url: "/bpm/process-definition/list",
    method: "get",
    params: query,
  });
}

export function getProcessDefinitionBpmnXML(id) {
  return request({
    url: "/bpm/process-definition/get-bpmn-xml?id=" + id,
    method: "get",
  });
}

// 查询任务进程列表
export function getTaskList(params) {
  return request({
    url: "/bpm/flowManagement/findFlowByGroup",
    method: "get",
    params,
  });
}
//查询多部门

export function getMoreDepartment(query) {
  return request({
    url: "/bpm/sys/user/findUserDeptInfo",
    method: "get",
    params: query,
  });
}