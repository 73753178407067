<template>
  <el-select v-model="taskdata.user" placeholder="请选择" filterable :multiple="taskdata.schema == 1 ? true : false"
    @change="change" :popper-append-to-body="false">
    <!-- 全公司 -->
    <template v-if="taskdata.scope == 0">
      <el-option v-for="user in users" :key="user.userId" :label="user.userName" :value="user.userId" />
    </template>
    <!-- 制定成员 -->
    <template v-if="taskdata.scope == 1">
      <el-option :label="user.userName" :value="user.userId" v-for="(user, i) in taskdata.candidates"
        :key="i"></el-option>
    </template>
    <!-- 用户组 -->
    <template v-if="taskdata.scope == 2">
      <el-option v-for="(user, i) in taskdata.candidates" :key="i" :label="user.userName"
        :value="user.userId"></el-option>
    </template>
  </el-select>
</template>

<script>
  import { intersectionWith, isArray } from "lodash-es";
  export default {
    props: {
      taskdata: {
        type: Object,
        default: {},
      },
      users: {
        type: Array,
        default: [],
      },
      taskDefKey: {
        type: String,
        default: "",
      },
    },
    methods: {
      change(e) {
        let selected = isArray(e) ? e : [e];
        let primaryList =
          this.taskdata.scope == 0 ? this.users : this.taskdata.candidates;
        if (this.taskdata.scope == 0) {
        }

        // let assigneeUsers = intersectionWith(
        //   selected,
        //   this.users,
        //   (a, b) => a == b.userId
        // );
        //上面那个改变会改变selected的顺序
        let assigneeUsers = selected.map((value) => {
          return intersectionWith(this.users, [value], (a, b) => a.userId == b)[0];
        });
        let obj = {
          assigneeUsers,
          taskDefKey: this.taskDefKey,
        };
        this.$emit("chooseUser", obj);
      },
    },
  };
</script>
<style></style>