import request from '@/utils/request'

// 查询审批表单分组
export function createForm(params) {
    return request({
        url: "/bpm/flowManagement/findGroup",
        method: "get",
        params,
    });
}


// 查询任务进程列表
export function getTaskList(params) {
    return request({
        url: "/bpm/flowManagement/findFlowByGroup",
        method: "get",
        params,
    });
}