import request from "@/utils/request";

// 查询审批表单分组
export function createForm(params) {
  return request({
    url: "/bpm/flowManagement/findGroup",
    method: "get",
    params,
  });
}

export function createFormById() {
  return request({
    url: "/bpm/flowManagement/findGroupAndFlow",
    method: "get",
    params: { isValid: true },
  });
}

// 新增审批表单分组
export function addGroup(data) {
  return request({
    url: "/bpm/flowManagement/addGroup",
    method: "post",
    data,
  });
}

// 编辑审批表单分组
export function editGroup(data) {
  return request({
    url: "/bpm/flowManagement/editGroup",
    method: "put",
    data,
  });
}

// 删除审批表单分组
export function deleteGroup(id) {
  return request({
    url: "/bpm/flowManagement/deleteGroup/" + id,
    method: "post",
  });
}

// 查询流程管理列表
export function getFlowManagementList(params) {
  return request({
    url: "/bpm/flowManagement/list",
    method: "get",
    params,
  });
}

// 查询组织结构
export function getDeptTree(params) {
  return request({
    url: "/bpm/flowManagement/deptTree",
    method: "get",
    params,
  });
}

// 查询部门下人员
export function getDepUserList(params) {
  return request({
    url: "/bpm/flowManagement/list",
    method: "get",
    params,
  });
}

// 自动生产流程标识key
export function getFlowKey(params) {
  return request({
    url: "/bpm/flowManagement/getFlowKey",
    method: "get",
    params,
  });
}

// 新增流程
export function addFlowManagement(data) {
  return request({
    url: "/bpm/flowManagement/add",
    method: "post",
    data,
  });
}

// 移动流程
export function moveflowManagement(id, groupId) {
  return request({
    url: `/bpm/flowManagement/move/${id}/${groupId}`,
    method: "post",
  });
}

// 删除流程
export function deleteFlow(id) {
  return request({
    url: `/bpm/flowManagement/deleteFlow/${id}`,
    method: "post",
  });
}

// 查询流程详情
export function flowManagement(id) {
  return request({
    url: `/bpm/flowManagement/${id}`,
    method: "get",
  });
}

// 修改流程
export function editFlowManagement(data) {
  return request({
    url: "/bpm/flowManagement/edit",
    method: "put",
    data,
  });
}

// 查询用户部门树
export function getFindDeptUser() {
  return request({
    url: "/bpm/flowManagement/findDeptUser",
    method: "get",
  });
}

// 查询流程草稿列表
export function getDraftList(params) {
  return request({
    url: "/bpm/flowManagement/draftList",
    method: "get",
    params,
  });
}

// 流程启用-关闭
export function flowManagementVisible(id, state) {
  return request({
    url: `/bpm/flowManagement/visible/${id}/${state}`,
    method: "post",
  });
}

// 流程定义详情
export function flowManagementFindFlowForm(params) {
  return request({
    url: `/bpm/flowManagement/findFlowForm`,
    method: "get",
    params,
  });
}

// 简单流程新增
export function simpleSave(data) {
  return request({
    url: "/bpm/simple/save",
    method: "post",
    data,
  });
}

// 简单流程编辑
export function simpleEdit(data) {
  return request({
    url: "/bpm/simple/edit",
    method: "PUT",
    data,
  });
}

// 生产条件表达式
export function createExpression(data) {
  return request({
    url: "/bpm/flowManagement/expression/create",
    method: "post",
    data,
  });
}

// 获取流程的表单权限设置
export function getFormPermission(params) {
  return request({
    url: "/bpm/flowManagement/getFormPermission",
    method: "get",
    params,
  });
}

// 表单权限设置
export function setFormPermission(data) {
  return request({
    url: "/bpm/flowManagement/setFormPermission",
    method: "PUT",
    data,
  });
}

// 加签
export function createSign(data) {
  return request({
    url: "/bpm/task/create-sign",
    method: "PUT",
    data,
  });
}

// 审批人编辑表单信息
export function updateFormInfo(data) {
  return request({
    url: "/bpm/process-instance/update-form-info",
    method: "post",
    data,
  });
}
// 流程表单变更
export function updateForm(data) {
  return request({
    url: "/bpm/process-instance/update-form",
    method: "post",
    data,
  });
}

// 评论
export function createComment(data) {
  return request({
    url: "/bpm/process-instance/create-comment",
    method: "post",
    data,
  });
}

// 评论
export function deleteComment(params) {
  return request({
    url: "/bpm/process-instance/delete-comment",
    method: "delete",
    params,
  });
}

// 查询所有发起人自选节点
export function getTaskAssignRulList(params) {
  return request({
    url: "/bpm/task-assign-rule/optionList",
    method: "get",
    params,
  });
}

// 查询用户组下用户信息
export function getGroupUserInfo(params) {
  return request({
    url: "/bpm/user-group/getUserInfo",
    method: "get",
    params,
  });
}

// 模糊搜索用户
export function findUserDeptByName(params) {
  return request({
    url: "/bpm/flowManagement/findUserDeptByName",
    method: "get",
    params,
  });
}

// 流程路径规划
export function getTrack(data) {
  return request({
    url: "/bpm/activity/getTrack",
    method: "post",
    data,
  });
}

// 查询流程实例预演轨迹
export function getfindTrack(instanceId) {
  return request({
    url: `/bpm/activity/findTrack/${instanceId}`,
    method: "get",
  });
}
export function freshTrack(data) {
  return request({
    url: '/bpm/activity/freshTrack',
    method: "post",
    data,
  });
}

//流程结束后添加抄送人
export function addSender(data) {
  return request({
    url: "/bpm/activity/addSender",
    method: "post",
    data,
  });
}

// 查询流程实例预演轨迹
export function handleReStart(instanceId) {
  return request({
    url: `/bpm/activity/restart/${instanceId}`,
    method: "get",
  });
}

// 添加评论回复
export function addTags(data) {
  return request({
    url: `/bpm/comment-tags/add`,
    method: "post",
    data,
  });
}

// 获取评论回复列表
export function getTagsList(userId) {
  return request({
    url: `/bpm/comment-tags/list?userId=${userId}`,
    method: "get",
  });
}

// 删除评论回复
export function deleteTags(tagId) {
  return request({
    url: `/bpm/comment-tags/delete?tagId=${tagId}`,
    method: "delete",
  });
}
