var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            staticClass: "return",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "Home" })
              }
            }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/ioc/angle-left.png"), alt: "" }
            }),
            _c("span", [_vm._v("发起审批")])
          ]
        ),
        _c("div", { staticClass: "current", on: { click: _vm.showDraft } }, [
          _c("img", {
            attrs: { src: require("@/assets/ioc/file-copy.png"), alt: "" }
          }),
          _c("span", [_vm._v("草稿箱")])
        ])
      ]),
      _c(
        "el-row",
        { staticClass: "main-con", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 16 } }, [
            _c("div", { ref: "formBody", staticClass: "form-con" }, [
              _c("div", { staticClass: "form-container" }, [
                _c("div", { staticClass: "form-header" }, [
                  _c("div", { staticClass: "form-icon" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: _vm.flowDetail.icon, alt: "" }
                    })
                  ]),
                  _c("span", { staticClass: "form-name" }, [
                    _vm._v(_vm._s(_vm.flowDetail.name))
                  ])
                ]),
                _vm.flowDetail.form.remark
                  ? _c("div", { staticClass: "divider" })
                  : _vm._e(),
                _vm.flowDetail.form.remark
                  ? _c("div", { staticClass: "form-description" }, [
                      _c("pre", [_vm._v(_vm._s(_vm.flowDetail.form.remark))])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  staticClass: "form-content"
                },
                [
                  _vm.isShow
                    ? _c("Parser", {
                        key: _vm.datekey,
                        attrs: {
                          "form-conf": _vm.detailForm,
                          nodesInfoOld: _vm.nodesInfo,
                          disabled: true,
                          preview: true,
                          tasks: _vm.tasks,
                          "condition-list": _vm.conditionList,
                          submitDisabled: _vm.submitDisabled,
                          initData: _vm.initData,
                          realDepartOptions: _vm.realDepartOptions,
                          candidate_dept: _vm.candidate_dept
                        },
                        on: {
                          submit: _vm.submitForm,
                          changefiles: _vm.changefiles,
                          clearState: _vm.clearState,
                          clearCache: _vm.clearCache,
                          preview: _vm.previewForm,
                          saveDraft: _vm.saveDraft,
                          clearTasks: _vm.clearTasks,
                          onPreview: _vm.onPreviewFile,
                          changeUploadList: _vm.changeUploadList,
                          changeLitigant: _vm.changeLitigant,
                          delCandidate_dept: _vm.delCandidate_dept,
                          combination: _vm.combination,
                          openDrawer: _vm.openDrawer
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "form-right" }, [
              _vm.tasks.length
                ? _c("div", { staticClass: "form-sidebar" }, [_vm._v("审批")])
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("timeline", {
                    attrs: { tasks: _vm.tasks, tip: _vm.tip },
                    on: { chooseUser: _vm.chooseUser }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "草稿箱(" + _vm.draftList.length + ")",
            visible: _vm.dialogVisible,
            width: "60%",
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "draft-con" }, [
            _c("img", {
              staticClass: "draft-ioc",
              attrs: { src: require("@/assets/ioc/info-circle.png"), alt: "" }
            }),
            _c("span", { staticClass: "draft-tip" }, [
              _vm._v("3个月内未更新的草稿将被自动删除")
            ])
          ]),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.draftList, height: "350" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "流程名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "draft-name" }, [
                              _c("img", {
                                staticStyle: { width: "24px", height: "24px" },
                                attrs: { src: scope.row.icon, alt: "" }
                              }),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "8px" } },
                                [_vm._v(_vm._s(scope.row.name))]
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "更新时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.formatDate(scope.row.updateTime))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "draft-btn" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "draft-btn cursor",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEdit(scope.row)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/ioc/pen.png"),
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "draft-btn-Edit" },
                                    [_vm._v("编辑")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "draft-btn cursor",
                                  staticStyle: { "margin-left": "20px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(scope.row)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/ioc/trash.png"),
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "draft-btn-Delete" },
                                    [_vm._v("删除")]
                                  )
                                ]
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("FilePreviewDialog", {
        attrs: {
          "file-url": _vm.fileUrl,
          fileName: _vm.fileName,
          "dialog-visible": _vm.openFilePreview
        },
        on: { closePreviewDialog: _vm.closePreviewDialog }
      }),
      _c("processDetails", {
        ref: "processDetails",
        attrs: { drawerData: _vm.drawerData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }