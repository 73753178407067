var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !_vm.tasks.length
        ? _c("el-empty", {
            attrs: {
              description: _vm.tip,
              image: "https://source.fungsong.com/3452097265742FYF1wx7cNHae.png"
            }
          })
        : _vm._l(_vm.tasks, function(item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("img", {
                    staticClass: "user_img",
                    attrs: { src: _vm.getTaskImg(item), alt: "" }
                  })
                ]),
                index != _vm.tasks.length - 1
                  ? _c("div", {
                      staticClass: "line",
                      style: {
                        borderLeft:
                          "2px  solid  " +
                          (item.result == 2
                            ? _vm.bg2
                            : item.result == 9999
                            ? _vm.bg3
                            : _vm.bg1) +
                          "\n           "
                      }
                    })
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "descriptions",
                      staticStyle: {
                        "align-items": "center",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c("div", { staticClass: "title " }, [
                        _vm._v(
                          _vm._s(item.result != 9999 ? item.name : "发起了审批")
                        )
                      ]),
                      item.taskType == "SERVICE_TASK"
                        ? _c("div", { staticClass: "descriptions-label" }, [
                            _vm._v("抄送人")
                          ])
                        : _vm._e(),
                      item.approveType
                        ? _c("div", { staticClass: "descriptions-label" }, [
                            _vm._v(_vm._s(_vm.keytype[item.approveType]))
                          ])
                        : _vm._e(),
                      item.assigneeUsers.length > 1
                        ? _c(
                            "div",
                            {
                              staticClass: "descriptions-img",
                              on: {
                                click: function($event) {
                                  return _vm.showSupplement(
                                    item.id || item.taskDefKey
                                  )
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.getIcon(
                                    _vm.revoFlag[item.id || item.taskDefKey]
                                  ),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  item.assigneeUsers && _vm.revoFlag[item.id || item.taskDefKey]
                    ? _c(
                        "div",
                        { staticClass: "descriptions" },
                        [
                          item.optionList && item.optionList.length
                            ? [
                                item.assigneeUsers.length == 0
                                  ? _c("optionalUser", {
                                      attrs: {
                                        taskdata: item.optionList[0],
                                        users: _vm.users,
                                        taskDefKey: item.taskDefKey
                                      },
                                      on: { chooseUser: _vm.chooseUser }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              ref: "featureRef" + index,
                              refInFor: true,
                              staticClass: "value_list"
                            },
                            _vm._l(item.assigneeUsers, function(sub, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "value scrollBarHidden",
                                  style: { "margin-top": i > 0 ? "10px" : "" }
                                },
                                [
                                  sub.avatarUrl
                                    ? _c("img", {
                                        attrs: { src: sub.avatarUrl, alt: "" }
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("@/assets/ioc/Group_41.png"),
                                          alt: ""
                                        }
                                      }),
                                  _vm.isHide(item) == "fail" ||
                                  _vm.isHide(item) == "normal"
                                    ? _c("img", {
                                        staticClass: "hide_img",
                                        attrs: {
                                          src: _vm.getTaskStatusImg(item),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "time",
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        color: "rgb(27, 27, 27)"
                                      },
                                      style: {
                                        "margin-left":
                                          _vm.isHide(item) == "fail" ||
                                          _vm.isHide(item) == "normal"
                                            ? "-14px"
                                            : "8px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            sub.userName.indexOf(
                                              "(节点审批人为空，已转交管理员)"
                                            ) == -1
                                              ? sub.userName
                                              : sub.userName.substring(
                                                  0,
                                                  sub.userName.indexOf(
                                                    "(节点审批人为空，已转交管理员)"
                                                  )
                                                )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  sub.userName.indexOf(
                                    "(节点审批人为空，已转交管理员)"
                                  ) != -1
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "节点审批人为空，已转交管理员",
                                            placement: "top-start"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-warning"
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: " supplement" }, [
                                    item.result
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.keytype[item.result])
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    item.endTime
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formattedTime(item.endTime)
                                              ) +
                                              " "
                                          )
                                        ])
                                      : item.createTime
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formattedTime(
                                                  item.createTime
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  item.result == 1
                    ? _c("div", { staticClass: "descriptions" }, [
                        _c("div", { staticClass: "timeDisplay" }, [
                          _vm._v(_vm._s(_vm.diffTime(item.createTime)))
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("已等待")])
                      ])
                    : _vm._e(),
                  item.reason
                    ? _c("div", { staticClass: "reason" }, [
                        _vm._v(_vm._s(item.reason))
                      ])
                    : _vm._e(),
                  item.comments && item.comments.length
                    ? _vm._l(item.comments, function(e, i) {
                        return _c("div", { key: i, staticClass: "reason" }, [
                          e.isWithdraw == 0
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "name" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "8px"
                                          }
                                        },
                                        [_vm._v(_vm._s(e.userName) + ":")]
                                      ),
                                      _vm.userId == e.userId
                                        ? [
                                            _vm.revocationFlag(e.createTime)
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteComment(
                                                          e
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("撤销")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("p", [_vm._v(_vm._s(e.comment))]),
                                  e.picture && e.picture.length
                                    ? _c(
                                        "div",
                                        { staticClass: "picture_list" },
                                        _vm._l(e.picture, function(sub, p) {
                                          return _c("div", { key: p }, [
                                            _c("img", {
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px"
                                              },
                                              attrs: { src: sub.url, alt: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.preview(sub)
                                                }
                                              }
                                            })
                                          ])
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  e.attachment && e.attachment.length
                                    ? _c(
                                        "div",
                                        { staticClass: "attachment_list" },
                                        _vm._l(e.attachment, function(sub, p) {
                                          return _c("div", { key: p }, [
                                            _c("p", [_vm._v(_vm._s(sub.name))]),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    staticClass: "yulan",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.preview(sub)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("预览")]
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _c("div", [_vm._v("评论已撤回")])
                        ])
                      })
                    : _vm._e()
                ],
                2
              )
            ])
          }),
      _c("FilePreviewDialog", {
        attrs: {
          "file-url": _vm.fileUrl,
          fileName: _vm.fileName,
          "dialog-visible": _vm.openFilePreview,
          type: _vm.type,
          width: _vm.type && _vm.type != "PC" ? "90%" : "50%"
        },
        on: { closePreviewDialog: _vm.closePreviewDialog }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }