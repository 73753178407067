<template>
    <div class="tab-box">
        <div>
            <div class="tab-header">
                <el-radio-group v-model="radio1" @input="monitor">
                    <el-radio-button label="1"><span>导出记录</span></el-radio-button>
                    <el-radio-button label="3"><span>导出附件</span></el-radio-button>
                    <el-radio-button label="2"><span>删除记录</span></el-radio-button>
                </el-radio-group>

                <div class="search-header" @click="$router.push({name:'DataManagement'})">
                    返回
                </div>
            </div>
        </div>

        <div class="sx-box" v-show="radio1 === '1'">
            <el-table :data="exportData" style="width: 100%" :header-cell-style="tableHeaderColor"
                :row-class-name="tableRowClassName" height="calc(100vh - 248px)" v-loading="loading">

                <el-table-column label="流程名称" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.fileName }}</span>
                    </template>
                </el-table-column>


                <el-table-column label="导出人员" align="center">
                    <template slot-scope="scope">
                        <div class="about-table-name">
                            <img style="width: 24px; height: 24px; margin-right: 8px;"
                                :src="scope.row.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                            <span style="color: rgb(86, 105, 236)"
                                @click.stop="UserClick(scope.row, scope.column, $event)">{{
                                scope.row.userName
                                }}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="name" label="导出时间" align="center">
                    <template slot-scope="scope">
                        <span>{{ parseTime(scope.row.createTime) }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="name" label="审批名称" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.defName }}</span>
                    </template>
                </el-table-column>


                <el-table-column prop="address" label="操作" align="center">
                    <template slot-scope="scope">
                        <div>
                            <span style="color: rgb(86, 105, 236); margin-right: 16px;"
                                @click.stop="handleExport(scope.row)">下载</span>
                        </div>
                    </template>
                </el-table-column>

            </el-table>
        </div>


        <div class="sx-box" v-show="radio1 === '2'">
            <el-table :data="deleteData" style="width: 100%" :header-cell-style="tableHeaderColor"
                :row-class-name="tableRowClassName" height="calc(100vh - 248px)">

                <el-table-column label="操作时间" align="center">
                    <template slot-scope="scope">
                        <span>{{ parseTime(scope.row.createTime) }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作人" align="center">
                    <template slot-scope="scope">
                        <div class="about-table-name">
                            <img style="width: 24px; height: 24px; margin-right: 8px;"
                                :src="scope.row.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                            <span style="color: rgb(86, 105, 236)"
                                @click.stop="UserClick(scope.row, scope.column, $event)">{{
                                scope.row.userName
                                }}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="筛选条件" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.queryCon }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="审批单数量" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num }}</span>
                    </template>
                </el-table-column>

            </el-table>
        </div>

        <div class="sx-box" v-show="radio1 === '3'">
            <el-table :data="AnnexList" style="width: 100%" :header-cell-style="tableHeaderColor"
                :row-class-name="tableRowClassName" height="calc(100vh - 248px)">

                <el-table-column prop="name" label="导出时间" align="center">
                    <template slot-scope="scope">
                        <span>{{ parseTime(scope.row.createTime) }}</span>
                    </template>
                </el-table-column>


                <el-table-column label="导出人员" align="center">
                    <template slot-scope="scope">
                        <div class="about-table-name">
                            <img style="width: 24px; height: 24px; margin-right: 8px;"
                                :src="scope.row.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                            <span style="color: rgb(86, 105, 236)"
                                @click.stop="UserClick(scope.row, scope.column, $event)">{{
                                scope.row.userName
                                }}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="name" label="筛选条件" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.queryCon }}</span>
                    </template>
                </el-table-column>


                <el-table-column prop="address" label="操作" align="center">
                    <template slot-scope="scope">
                        <div>
                            <span style="color: rgb(86, 105, 236); margin-right: 16px;"
                                @click.stop="handleExport(scope.row)">下载</span>
                        </div>
                    </template>
                </el-table-column>

            </el-table>
        </div>

    </div>
</template>

<script>
    import { parseTime } from "@/utils/time";
    import { getExportList, getDEList, getAnnexList } from "@/api/bpm/dataManagement.js";
    import axios from 'axios';
    export default {
        name: 'HFIMElectronWorkbenchOperationRecord',

        data() {
            return {
                loading: true,
                queryParams: {
                    page: 1,
                    pageSize: 9999999,
                },
                parseTime,
                radio1: '1',
                exportData: [],
                deleteData: [],
                AnnexList: [],
            };
        },

        async mounted() {
            await this.getEXportList();
            await this.getDEList();
            await this.getAnnexList();
        },

        methods: {
            // 获取导出数据
            async getEXportList() {
                // 处理查询参数
                const { data } = await getExportList(this.queryParams);
                if (data) {
                    this.exportData = data.list;
                    this.loading = false;
                }
            },
            // 获取删除数据
            async getDEList() {
                const { data } = await getDEList(this.queryParams)
                if (data) {
                    this.deleteData = data.list;
                }
            },

            // 获取附件数据
            async getAnnexList() {
                const { data } = await getAnnexList(this.queryParams)
                if (data) {
                    this.AnnexList = data.list;
                }
            },
            // 表头样式
            tableHeaderColor({ row, column, rowIndex, columnIndex }) {
                if (this.radio1 === '1') {
                    if (rowIndex === 0) {
                        switch (columnIndex) {
                            case 0:
                                return { 'background': 'rgb(86, 105, 236)', 'border-radius': '6px 0px 0px 0px', 'color': 'rgb(250, 251, 252)' };
                                break;
                            case 4:
                                return { 'background': 'rgb(86, 105, 236)', 'border-radius': '0px 6px 0px 0px', 'color': 'rgb(250, 251, 252)' };
                                break;
                            default:
                                return { 'background': 'rgb(86, 105, 236)', 'color': 'rgb(250, 251, 252)' };
                                break;
                        }
                    }
                } else {
                    if (rowIndex === 0) {
                        switch (columnIndex) {
                            case 0:
                                return { 'background': 'rgb(86, 105, 236)', 'border-radius': '6px 0px 0px 0px', 'color': 'rgb(250, 251, 252)' };
                                break;
                            case 3:
                                return { 'background': 'rgb(86, 105, 236)', 'border-radius': '0px 6px 0px 0px', 'color': 'rgb(250, 251, 252)' };
                                break;
                            default:
                                return { 'background': 'rgb(86, 105, 236)', 'color': 'rgb(250, 251, 252)' };
                                break;
                        }
                    }
                }
            },
            // 页签切换
            monitor() {

            },
            // 点击用户卡片
            UserClick(row, column, event) {
                this.openUserCard(row.userId)
            },
            tableRowClassName({ row, rowIndex }) {
                return 'operation-Record';
            },
            // 下载导出文件
            handleExport(row) {
                axios
                    .get(row.fileUrl, {
                        responseType: 'blob', // 确保返回数据为 Blob
                    })
                    .then((response) => {
                        const blob = response.data;
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = row.fileName; // 设置自定义文件名
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(link.href); // 清理URL对象
                    })
                    .catch((error) => {
                        console.error("文件下载失败:", error);
                    });
            },
        },
    };
</script>

<style scoped>
    ::v-deep .operation-Record {
        background-color: rgb(243, 244, 246) !important;
    }

    ::v-deep .el-table__body-wrapper {
        background-color: rgb(243, 244, 246) !important;
    }

    ::v-deep .custom-drawer {
        background: linear-gradient(0deg, rgba(250, 251, 252, 0) 78.98%, rgba(86, 105, 236, 0.3) 100%), rgba(255, 255, 255, 1);
    }

    ::v-deep #el-drawer__title {
        margin-bottom: 8px;
    }

    ::v-deep .el-drawer__body {
        flex: 1 1 0%;
        overflow: auto;
        padding-top: 0px;
    }

    .about-table-name {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tab-box {
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        position: relative;
    }

    .search-header {
        width: 76px;
        height: 28px;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 3px 12px;
        border-radius: 2px;
        background: rgba(243, 244, 246, 1);
        text-align: center;
        color: rgba(27, 27, 27, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
    }

    .tab-header {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    ::v-deep .el-radio-button__inner {
        width: 76px;
        height: 28px;
        padding: 7px 11px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 2px;
        background: rgba(243, 244, 246, 1) !important;
        border: 0px !important;
        margin-right: 12px;
    }

    ::v-deep .el-radio-button:first-child .el-radio-button__inner {
        border: 0px !important;
        border-radius: 2px !important;
        margin-right: 12px !important;
    }

    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border-radius: 2px !important;
        background: rgba(86, 105, 236, 0.15) !important;
        color: rgba(86, 105, 236, 1) !important;
    }

    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border: 0px !important;
        box-shadow: none !important;
    }

    ::v-deep .el-radio-button__inner:hover {
        color: rgba(86, 105, 236, 1) !important;
    }



    ::v-deep .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
        margin-bottom: 0px;
    }

    ::v-deep .el-input__inner {
        border: 0px !important;
        border-radius: 6px !important;
        background: rgba(243, 244, 246, 1) !important;
        color: #848484 !important;
    }

    ::v-deep .el-input__prefix {
        height: 100% !important;
        left: -3px !important;
    }

    .content-list {
        width: 100%;
        margin-top: 16px;
    }

    ::v-deep .el-form--inline .el-form-item {
        margin-right: 8px !important;
    }

    .el-button-box {
        width: 100px;
        height: 34px;
        background-color: #f3f4f6 !important;
        color: #1b1b1b !important;
        border: 0px !important;
        padding: 10px 12px 10px 8px !important;
        box-sizing: border-box;
    }

    ::v-deep .el-radio-button {
        width: 76px;
        height: 28px;
        border: 0px;
        margin-right: 12px;
    }

    /* ::v-deep .el-popper {
        width: 76px;
    } */

    ::v-deep .el-dropdown-menu--small .el-dropdown-menu__item {
        padding: 0px !important;
        width: 76px;
    }

    ::v-deep .el-dropdown-menu__item {
        display: block !important;
        text-align: center !important;
    }

    .el-dro-item {
        display: block !important;
        text-align: center !important;
        height: 32px !important;
    }

    .sx-box {
        margin-top: 16px;

    }

    .sx-name {
        color: rgba(0, 0, 0, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 7px;
    }

    .sx-btn-box {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }

    .sx-btn {
        width: 112px;
        height: 44px;
        border-radius: 2px;
        background: rgba(243, 244, 246, 1);
        line-height: 44px;
        text-align: center;
        color: var(--, rgba(132, 132, 132, 1));
        font-size: 13px;
    }

    .sx-btns {
        width: 204px;
        height: 44px;
        border-radius: 2px;
        background: rgba(86, 105, 236, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 13px;
        line-height: 44px;
        text-align: center;
    }

    .drawer__title {
        text-align: left;
        color: rgba(27, 27, 27, 1);
        font-family: "Noto Sans SC";
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
    }
</style>