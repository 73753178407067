
import { getInfo } from "@/api/login";
import { Message } from 'element-ui';
export default (router) => {
    // 全局前置守卫
    router.beforeEach(async (to, from, next) => {
        if (to.query.token) {
            localStorage.setItem('key_user_token', JSON.stringify(to.query.token));
            const { code, data, msg } = await getInfo();
            if (code == 0) {
                localStorage.setItem('store-user-info', JSON.stringify({ userInfoState: data }));
            } else {
                Message.error(msg);
            }
        }
        next(); // 继续导航
    })

    return router
}