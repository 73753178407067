var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        placeholder: "请选择",
        filterable: "",
        multiple: _vm.taskdata.schema == 1 ? true : false,
        "popper-append-to-body": false
      },
      on: { change: _vm.change },
      model: {
        value: _vm.taskdata.user,
        callback: function($$v) {
          _vm.$set(_vm.taskdata, "user", $$v)
        },
        expression: "taskdata.user"
      }
    },
    [
      _vm.taskdata.scope == 0
        ? _vm._l(_vm.users, function(user) {
            return _c("el-option", {
              key: user.userId,
              attrs: { label: user.userName, value: user.userId }
            })
          })
        : _vm._e(),
      _vm.taskdata.scope == 1
        ? _vm._l(_vm.taskdata.candidates, function(user, i) {
            return _c("el-option", {
              key: i,
              attrs: { label: user.userName, value: user.userId }
            })
          })
        : _vm._e(),
      _vm.taskdata.scope == 2
        ? _vm._l(_vm.taskdata.candidates, function(user, i) {
            return _c("el-option", {
              key: i,
              attrs: { label: user.userName, value: user.userId }
            })
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }