<template>
  <el-dialog title="预览" :visible.sync="$props.dialogVisible" :before-close="handleClose" :modal="false"
    :close-on-click-modal="false" @open="open" :width="width" :custom-class="'evaluate-dialog'">
    <el-button @click="downLoad" style="margin-bottom: 12px;float: left;" size="mini">下载</el-button>
    <!-- <a :href="fileUrl" download class="download" size="mini" v-if="type == 'Android' || type == 'PC'">下载</a> -->
    <div v-if="getFileType(fileUrl) == 'docx' || getFileType(fileUrl) == 'doc'">
      <vue-office-docx :src="fileUrl" style="height: 70vh; overflow: auto" @rendered="rendered" @error="errorHandler" />
    </div>
    <div v-else-if="
        getFileType(fileUrl) == 'xlsx' || getFileType(fileUrl) == 'xls'
      " :style="{ transform: `scale(${scale})` }">
      <vue-office-excel v-if="type == 'PC'" :src="fileUrl" style="height: 90vh; overflow: auto" @rendered="rendered"
        @error="errorHandler" ref="excelViewer" />
      <vue-pinch-zoom ref="pinchZoom" v-if="!(type == 'PC')">
        <vue-office-excel :src="fileUrl" style="height: 90vh; overflow: auto" @rendered="rendered" @error="errorHandler"
          ref="excelViewer" />
      </vue-pinch-zoom>
    </div>
    <div v-else-if="getFileType(fileUrl) == 'pdf'">
      <!-- <vue-office-pdf :src="fileUrl" style="height: 70vh; overflow: auto" @rendered="rendered" @error="errorHandler"
        :options="{
          width: '100%',
          overflow: 'auto',
        }" /> -->
      <embed :src="fileUrl" style="width: 100%;height: 500px;">
    </div>
    <div v-else-if="getFileType(fileUrl) == 'txt'">
      <el-input type="textarea" autosize v-model="txtContain" readonly>
      </el-input>
    </div>
    <div v-else-if="getFileType(fileUrl) == 'mp4'">
      <video :src="fileUrl" autoplay controls></video>
    </div>
    <div v-else-if="
        getFileType(fileUrl) == 'jpg' ||
        getFileType(fileUrl) == 'png' ||
        getFileType(fileUrl) == 'jpeg'
      ">
      <el-image style="width: 100%" :src="fileUrl"></el-image>
    </div>
    <div v-else>
      <p>暂不支持当前格式预览</p>
    </div>
  </el-dialog>
</template>

<script>
  import VuePinchZoom from 'vue-pinch-zoom';

  import axios from "axios";
  // 引入VueOffice组件
  import VueOfficeDocx from "@vue-office/docx";
  import VueOfficeExcel from "@vue-office/excel";
  import VueOfficePdf from "@vue-office/pdf";
  // 引入相关样式
  import "@vue-office/docx/lib/index.css";
  import "@vue-office/excel/lib/index.css";
  export default {
    name: "FilePreviewDialog",
    components: {
      VueOfficeDocx,
      VueOfficeExcel,
      VueOfficePdf,
      VuePinchZoom,
    },
    props: {
      fileUrl: {
        type: String,
        default: "",
      }, // 文件url
      fileName: {
        type: String,
        default: "",
      },
      dialogVisible: {
        // 控制显示
        type: Boolean,
        default: false,
      },
      width: {
        // 宽度
        type: String,
        default: '50%',
      },
      type: {
        type: String,
        default: "PC",
      },
    },
    data() {
      return {
        txtContain: "",
        scale: 1,
        systemType: ''
      };
    },
    mounted() {
      console.log(this.type);
    },

    methods: {
      open() {
        if (this.getFileType(this.fileUrl) == "txt") {
          let xhr = new XMLHttpRequest();
          xhr.open("get", this.fileUrl, true);
          xhr.responseType = "blob";
          xhr.onload = (e) => {
            if (e.currentTarget.status == 200) {
              const reader = new FileReader();
              reader.readAsText(e.currentTarget.response); //非常重要
              reader.onload = () => {
                this.txtContain = reader.result;
              };
            }
          };
          xhr.send();
        }
      },
      // downLoad() {
      //   axios
      //     .get(this.fileUrl, { responseType: "blob" })
      //     .then((response) => {
      //       const blob = new Blob([response.data]);
      //       const link = document.createElement("a");
      //       link.href = URL.createObjectURL(blob);
      //       link.download = this.fileName;
      //       link.click();
      //       URL.revokeObjectURL(link.href);
      //     })
      //     .catch(console.error);
      // },
      downLoad() {
        // window.webkit.messageHandlers.downloadFileMethod.postMessage(this.fileUrl);
        if (this.downloadFile) {
          this.downloadFile({ url: this.fileUrl, fileName: this.fileUrl.substring(this.fileUrl.lastIndexOf('/') + 1) });
        }
      },
      // 获取文件类型
      getFileType(fileUrl) {
        if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
          const fileType = fileUrl.split(".").pop().toLowerCase(); // 文件的扩展名（格式）
          // console.log("type:", fileType);
          return fileType;
        } else {
          return "docx";
        }
      },

      // 渲染结果
      rendered() {
        console.log("渲染完成");
      },

      errorHandler() {
        // this.$notify.error("加载失败");
      },

      // 关闭
      handleClose(done) {
        this.$emit("closePreviewDialog", false);
      },



    },
  };
</script>
<style scoped>
  ::v-deep .el-dialog__body {
    padding: 0 20px 20px 20px !important;
  }

  .download {
    margin-bottom: 12px;
    display: inline-block;
    padding: 5px 15px;
    color: white;
    background-color: rgb(24, 144, 255);
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    font-weight: bold;
  }

  ::v-deep .evaluate-dialog {
    .el-dialog__header {
      display: flex;
      padding: 20px 20px 10px;

      .el-dialog__headerbtn {
        top: 8px;
        right: 4px;
      }
    }
  }

  ::v-deep .docx-wrapper {
    display: block;
  }

  /* 滚动条样式 */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* 滑块样式 */
  ::-webkit-scrollbar-thumb {
    background: rgba(245, 246, 249, 1);
    border-radius: 10px;
  }

  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: #fcfcfc;
    border-radius: 2px;
  }

  /* 横向滚动条特殊样式 */
  ::-webkit-scrollbar:horizontal {
    height: 8px;
    /* 横向滚动条的厚度 */
  }
</style>