<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose" width="620px"
    :custom-class="'initiatingRange'" @close="close" :modal-append-to-body="false">
    <div class="main">
      <div class="left">
        <div style="padding: 12px 20px 0 20px" class="head">
          <div class="type_list" v-if="addType == 3">
            <div v-for="(item, index) in typeList" :key="index" class="type_item">
              <img class="img" :src="typeActive == index ? item.u_img : item.img" @click="chooseType(index)" />
              <span>{{ item.name }}</span>
            </div>
          </div>
          <el-input v-if="typeActive == 0 && !isGroup" v-model="findUserName" clearable placeholder="请输入关键词"
            @input="findUserDeptByName" style="margin-bottom: 8px"></el-input>
          <el-input v-if="typeActive == 1" v-model="filterText" clearable placeholder="请输入关键词"
            style="margin-bottom: 8px"></el-input>
          <el-input v-if="typeActive == 2" v-model="roleName" clearable placeholder="请输入关键词" @input="findRoleName"
            style="margin-bottom: 8px"></el-input>
          <!-- 面包屑 -->
          <div class="breadcrumb" v-if="typeActive == 0 && !findUserName">
            <div class="breadcrumb_item" :class="index != breadcrumb.length - 1 ? 'pointer' : ''"
              v-for="(item, index) in breadcrumb" :key="index" @click="chooseBreadcrumb(item, index)">
              <img src="@/assets/images/20240119home.png" v-if="index == 0" alt="" />
              <i v-else class="el-icon-d-arrow-right color"></i>
              <p :class="index == 0 ? 'color' : ''">{{ item.label }}</p>
            </div>
          </div>
        </div>
        <div class="tree">
          <!-- 搜索人员 -->
          <div v-show="typeActive == 0 && findUserName">
            <div class="tree_framework" v-for="(item, index) in findUserList" :key="item.userId">
              <div class="two" :class="item.flag ? 'bg_avtive' : ''">
                <div class="check_box" @click="checkfinduser(item, index)" style="cursor: pointer">
                  <i v-show="item.flag" class="el-icon-check"></i>
                </div>

                <div class="userinfo">
                  <img v-if="item.avatarUrl" :src="item.avatarUrl" alt="" />
                  <img v-else src="/static/img/20240118touxiang.0dcbf29b.png" alt="" />
                  <span>{{ item.userName }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 架构 -->
          <div v-show="typeActive == 0 && !findUserName">
            <div class="tree_framework">
              <div class="one" v-if="!radio">
                <div>
                  <div class="check_box" @click="checkBoxAll()" style="cursor: pointer">
                    <i class="el-icon-check" v-if="depAll"></i>
                  </div>
                  <span>全部</span>
                </div>
              </div>
            </div>
            <!-- 部门 -->
            <div class="tree_framework" v-for="(item, index) in presentDep.children" :key="index">
              <div class="one" :class="item.flag ? 'bg_avtive' : ''">
                <div class="">
                  <div class="check_box" :class="isUser ? 'disabled_box' : ''" @click="checkBox(1, item)"
                    style="cursor: pointer">
                    <i v-show="item.flag" class="el-icon-check"></i>
                  </div>
                  <span>{{ item.label }}</span>
                </div>
                <p @click="choosePresentDep(item)" :class="item.flag ? 'level' : ''">
                  下级
                </p>
              </div>
            </div>
            <!-- 人 -->
            <template v-if="!isGroup">
              <div class="tree_framework" v-for="(item, index) in presentDep.userList" :key="item.id">
                <div class="two" :class="item.flag ? 'bg_avtive' : ''">
                  <div class="check_box" @click="checkBox(2, item)" style="cursor: pointer">
                    <i v-show="item.flag" class="el-icon-check"></i>
                  </div>

                  <div class="userinfo">
                    <img v-if="item.avatarUrl" :src="item.avatarUrl" alt="" />
                    <img v-else src="/static/img/20240118touxiang.0dcbf29b.png" alt="" />
                    <span>{{ item.userName }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- 用户组 -->
          <div v-show="typeActive == 1">
            <el-tree :data="userGroup" ref="tree" node-key="id" :props="defaultProps" default-expand-all
              :filter-node-method="filterNode">
              <span class="custom-tree-node" slot-scope="{ node, data }" @click="chooseGroup(data)">
                <div>
                  <i class="el-icon-user-solid mr10"></i>
                  <span>{{ node.label }}</span>
                </div>
                <span v-if="data.parentId > 0">
                  <i class="el-icon-circle-check" v-if="data.flag"></i>
                  <div class="circle" v-else></div>
                </span>
              </span>
            </el-tree>
          </div>
          <!-- 角色 -->
          <div v-show="typeActive == 2">
            <div class="tree_item" v-for="(item, index) in role" :key="index" @click="chooserole(index, item)">
              <span>{{ item.roleName }}</span>

              <i class="el-icon-circle-check" v-if="item.flag"></i>
              <div class="circle" v-else></div>
            </div>
          </div>
          <!-- 主管 -->
          <div v-if="addType == 4">
            <div class="tree_item" v-for="(item, index) in deptMangerList" :key="index"
              @click="chooseDept(index, item)">
              <span>{{ item.name }}</span>
              <i class="el-icon-circle-check" v-if="item.flag"></i>
              <div class="circle" v-else></div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <p style="margin-bottom: 16px;text-align: left;">已选择</p>
        <div class="list">
          <div class="list_item" v-for="(item, index) in chooseList" :key="index">
            <div>
              <img v-if="item.avatarUrl" :src="item.avatarUrl" alt="" />
              <span>{{ item.name }}</span>
            </div>
            <i class="el-icon-error" style="color: #ff424c; cursor: pointer"
              @click="deleteList(item, index, item.type)"></i>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div @click="()=>{this.dialogVisible = false;this.breadcrumb = []}" class="cancel">
        取消
      </div>
      <div @click="confirm" class="submit">
        确定
      </div>

    </span>
  </el-dialog>
</template>

<script>
  import { debounce } from 'lodash-es';
  import { uniqBy } from "lodash-es";
  import { mapState } from "vuex";
  import { getDepUserList, findUserDeptByName } from "@/api/bpm/flow.js";
  export default {
    props: {
      title: {
        type: String,
        default: "发起范围",
      },
      // 是否单选
      radio: {
        type: Boolean,
        default: false,
      },
      //是否必须选择
      required: {
        type: Boolean,
        default: true,
      },
      //addType 0架构 1用户组 2角色 3全部 4主管
      addType: {
        type: String,
        default: "3",
      },
      // 只选择人员,不选择部门
      isUser: {
        type: Boolean,
        default: false,
      },
      // 只选择部门,不选择人员
      isGroup: {
        type: Boolean,
        default: false,
      },
      selectedList: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data() {
      return {
        defaultProps: {
          children: "children",
          label: "name",
        },
        roleName: "", //角色搜索
        filterText: "", //用户组搜索
        findUserName: "", //搜索人员
        findUserList: [], //搜索人员列表
        breadcrumb: [], //面包屑
        userimg: require("@/assets/images/20240118touxiang.png"),
        deptTree: [], //架构数据
        presentDep: [], //当前选择架构
        // depUserList: [], //架构下的人
        depAll: false, //全部
        userGroup: [], //用户组下拉
        role: [], //角色下拉
        // $store.state.bpm.roleOptions 角色
        // $store.state.bpm.userGroupOptions 用户组
        dialogVisible: false, //弹窗开关
        chooseList: [], //已选列表
        roleList: [],
        mangerList: [],
        userGroupList: [],
        deptList: [],
        typeActive: 0, //范围类型
        input: "",
        typeList: [
          {
            img: require("@/assets/images/20240115jiagou.png"),
            u_img: require("@/assets/images/20240116u_jiagou.png"),
            name: "按架构",
          },
          {
            img: require("@/assets/images/20240115yonghuzu.png"),
            u_img: require("@/assets/images/20240116u_yonghuzu.png"),
            name: "按用户组",
          },
          {
            img: require("@/assets/images/20240115juese.png"),
            u_img: require("@/assets/images/20240116u_juese.png"),
            name: "按角色",
          },
        ],
        deptMangerList: [{ name: "直接主管", id: 1, flag: false },
        { name: "部门主管", id: 2, flag: false },
        { name: "连续多级主管", id: 3, flag: false },
        ],
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
      role: {
        deep: true,
        handler(val) {
          let arr = [];
          val.map((item) => {
            if (item.flag) {
              arr.push({
                name: item.roleName,
                id: item.roleId,
                type: "role",
              });
            }
          });
          this.roleList = arr;
          // this.chooseList = arr;
          this.setChooseList();
        },
      },
      deptMangerList: {
        deep: true,
        handler(val) {
          let arr = [];
          val.map((item) => {
            if (item.flag) {
              arr.push({
                name: item.name,
                id: item.id,
                type: "manager",
              });
            }
          });
          this.mangerList = arr;
          // this.chooseList = arr;
          this.setChooseList();
        },

      },
      userGroup: {
        deep: true,
        handler(val) {
          let arr = [];
          val.map((item) => {
            item.children.map((e) => {
              if (e.flag) {
                arr.push({
                  name: e.name,
                  id: e.id,
                  type: "group",
                });
              }
            });
          });
          this.userGroupList = arr;
          // this.chooseList = arr;
          this.setChooseList();
        },
      },
      addType(val) {
        if (val != 3) {
          this.typeActive = val;
        }
      },
      userGroupOptions(newVal) {
        // console.log(newVal, "hahah");
        this.userGroup = newVal;
        this.userGroup.map((e) => {
          e.children.map((i) => {
            this.$set(i, "flag", false);
          });
        });
      },
      roleOptions(newVal) {
        this.role = newVal;
        this.role.map((e) => {
          this.$set(e, "flag", false);
        });
      },
      depUserList(newVal) {
        this.deptTree = newVal;
        this.clearDep(this.deptTree[0]);
        this.choosePresentDep(newVal[0]);
      },
      selectedList(newVal) {
        this.clearDep(this.deptTree[0]);
        newVal.forEach((item) => {
          if (item.type == "dept" || item.type == "uid") {
            this.typeActive = 0;
            this.setDepFlag(item.id);
          } else if (item.type == "role") {
            this.typeActive = 2;

            const roleItem = this.role.find((i) => {
              return i.roleId == item.id;
            });

            roleItem && (roleItem.flag = true);

          } else if (item.type == "group") {
            this.typeActive = 1;
            newVal.forEach((e) => {
              this.userGroup.forEach((s) => {
                s.children.forEach((i) => {
                  // i.flag = e.id == i.id ? true : false;
                  if (e.id == i.id) {
                    i.flag = true;
                  }
                });
              });
            });
          } else if (item.type == "manager") {
            const managerItem = this.deptMangerList.find((i) => {
              return i.id == item.id;
            });
            managerItem && (managerItem.flag = true);
          }
        });
        // }, 1000);
      },
      deptTree: {
        deep: true,
        handler(val) {
          let list = [];
          let fn = (arr) => {
            if (arr.children && arr.children.length) {
              for (let index = 0; index < arr.children.length; index++) {
                let item = arr.children[index];
                if (item.flag) {
                  list.push({
                    id: item.id,
                    name: item.label,
                    type: "dept",
                    data: item,
                  });
                }
                if (
                  (item.children && item.children.length) ||
                  (item.userList && item.userList.length)
                ) {
                  fn(item);
                }
              }
            }
            if (arr.userList && arr.userList.length) {
              for (let index = 0; index < arr.userList.length; index++) {
                let item = arr.userList[index];
                if (item.flag) {
                  list.push({
                    id: item.userId,
                    name: item.userName,
                    avatarUrl: item.avatarUrl,
                    type: "uid",
                    data: item,
                  });
                }
                if (item.userList && item.userList.length) {
                  fn(item);
                }
              }
            }
          };
          fn(val[0]);
          list = uniqBy(list, (obj) => obj.id);
          this.deptList = list;
          // this.chooseList = list;

          this.setChooseList();
        },
      },
    },
    computed: {
      ...mapState({
        userGroupOptions: (state) => state.bpm.groupList,
        roleOptions: (state) => state.bpm.roleOptions,
        depUserList: (state) => state.bpm.depUserList,
      }),
    },
    created() {
      if (this.addType != 3) {
        this.typeActive = this.addType;
      }
      this.initOptions();

    },

    methods: {
      setChooseList() {
        this.chooseList = [
          ...(this.deptList || []),
          ...(this.userGroupList || []),
          ...(this.roleList || []),
          ...(this.mangerList || []),
        ];
      },
      initOptions() {
        if (this.depUserList && this.depUserList.length) {
          this.getDepUserList();
        }
        if (this.userGroupOptions && this.userGroupOptions.length) {
          this.getUserGroupOptions();
        }
        if (this.roleOptions && this.roleOptions.length) {
          this.getRoleOptions();
        }
      },
      getDepUserList() {
        this.deptTree = this.depUserList;
        this.clearDep(this.deptTree[0]);
        this.choosePresentDep(this.depUserList[0]);
      },
      getUserGroupOptions() {
        this.userGroup = this.userGroupOptions;
        this.userGroup.map((e) => {
          e.children.map((i) => {
            this.$set(i, "flag", false);
          });
        });
      },
      getRoleOptions() {
        this.role = this.roleOptions;
        this.role.map((e) => {
          this.$set(e, "flag", false);
        });
      },
      findRoleName(e) {
        console.log("|||");
        this.$store.dispatch("bpm/listSimpleRoles", e);
      },
      // 树列表搜索
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      checkfinduser(item, index) {
        if (!item.deptId) {
          this.$message.error("该人员没有部门,无法选择");
          return;
        }
        if (this.radio) {
          this.clearDep(this.deptTree[0]);
          this.findUserList.map((e) => (e.flag = false));
        }
        this.findUserList[index].flag = !this.findUserList[index].flag;
        this.setDepFlag(item.userId);
      },
      setDepFlag(id) {
        let fn = (arr, shouldContinue = true) => {
          if (!shouldContinue) return;
          if (arr.userList && arr.userList.length) {
            // console.log(arr);
            arr.userList.map((i) => {
              if (i.userId == id) {
                i.flag = !i.flag;
                shouldContinue = false; // 这将结束后续递归
                shouldContinue = false;
              }
            });
          }

          if (arr.children && arr.children.length) {
            arr.children.map((i) => {
              if (i.id == id) {
                i.flag = !i.flag;
              }
              if (
                (i.userList && i.userList.length) ||
                (i.children && i.children.length)
              )
                fn(i, shouldContinue);
            });
          }
          // if (
          //   (arr.children && arr.children.length) ||
          //   (arr.userList && arr.userList.length)
          // ) {
          // }
        };
        fn(this.deptTree[0]);
      },
      // 模糊搜索人员
      async findUserDeptByName(name) {
        const { data } = await findUserDeptByName({ name });
        this.findUserList = data;
        this.findUserList.map((i) => {
          this.$set(i, "flag", false);
          this.chooseList.map((e) => {
            if (e.id == i.userId) {
              i.flag = true;
            }
          });
        });
      },
      chooseBreadcrumb(item, index) {
        this.breadcrumb.splice(index + 1, 999);
        this.presentDep = item;
        this.depAll = false;
      },
      // 选择架构级别
      choosePresentDep(data) {
        if (this.depAll || data.flag) return;
        this.breadcrumb.push(data);
        this.presentDep = data;
      },
      // 点击确定
      confirm: debounce(function () {
        if (!this.chooseList.length && this.required) {
          this.$message.error("请勾选需要指定的人员");
          return;
        }
        this.dialogVisible = false;
        this.breadcrumb = []
        this.$emit("confirm", this.chooseList);
      }, 500),
      // 删除已选
      deleteList(item, index, type) {
        console.log(`output->1`, item, index, type)
        // type 0架构 1用户组 2角色
        switch (type) {

          case "uid":
            if (this.findUserList && this.findUserList.length) {
              let index = this.findUserList.findIndex((e) => e.userId == item.id);
              if (index != -1) {
                this.findUserList[index].flag = false;
              }
            }
            this.setDepFlag(item.id);
            setTimeout(() => {
              console.log(`output->this.chooseList`, this.chooseList)
            }, 100)

            break;
          case "group":
            let one_index = "";
            let two_index = "";
            this.userGroup.map((group, index) => {
              group.children.map((s, i) => {
                if (item.id == s.id) {
                  one_index = index;
                  two_index = i;
                }
              });
            });
            this.userGroup[one_index].children[two_index].flag = false;
            break;
          case "role":
            let roleindex = this.role.findIndex((e) => e.roleId == item.id);
            this.role[roleindex].flag = false;
            break;
          case "manager":
            let deptMIndex = this.deptMangerList.findIndex((e) => e.id == item.id);
            this.deptMangerList[deptMIndex].flag = false;
            break;
          case "dept":
            this.setDepFlag(item.id);
            break;

          default:
            break;
        }
      },
      init() {
        this.chooseList = [];
        this.role.map((e) => (e.flag = false));
        this.userGroup.map((e) => (e.flag = false));
      },
      // 选择角色
      chooserole(index, item) {
        if (this.radio) {
          this.clearDep();
        }
        this.role[index].flag = !this.role[index].flag;
      },
      chooseDept(index, item) {
        if (this.radio) {
          this.clearDep();
        }
        this.deptMangerList[index].flag = !this.deptMangerList[index].flag;
      },
      // 选择用户组
      chooseGroup(data) {
        if (data.parentId == -1) return;
        if (this.radio) {
          this.clearDep();
        }
        let one_index = this.userGroup.findIndex((e) => e.id == data.parentId);
        let two_index = this.userGroup[one_index].children.findIndex(
          (e) => e.id == data.id
        );
        this.userGroup[one_index].children[two_index].flag =
          !this.userGroup[one_index].children[two_index].flag;
      },
      // 选择全部架构
      checkBoxAll() {
        this.depAll = !this.depAll;
        let { presentDep } = this;
        if (!this.isUser) {
          if (presentDep.children && presentDep.children.length) {
            presentDep.children.map((e) => {
              e.flag = this.depAll;
            });
          }
        }
        if (!this.isGroup) {
          if (presentDep.userList && presentDep.userList.length) {
            presentDep.userList.map((e) => {
              e.flag = this.depAll;
            });
          }
        }
      },
      // 选择架构
      checkBox(type, e) {
        this.depAll = false;
        if (this.radio) {
          this.clearDep(this.deptTree[0]);
        }
        // type 1一级  2二级
        if (type == 1) {
          if (this.isUser) return;
          this.setDepFlag(e.id);
          // presentDep.children[index].flag = !presentDep.children[index].flag;
        }
        if (type == 2) {
          this.setDepFlag(e.userId);
          // presentDep.userList[index].flag = !presentDep.userList[index].flag;
        }
      },
      // 选择类型
      chooseType(index) {
        // this.findUserName = "";
        // this.init();
        this.typeActive = index;
        // if (index === 0) this.chooseList = this.deptList;
        // if (index === 1) this.chooseList = this.userGroupList;
        // if (index === 2) this.chooseList = this.roleList;
        this.setChooseList();
        // this.clearDep(this.deptTree[0]);
      },
      close() {
        this.depAll = false;
        this.findUserName = "";
        this.findUserList = [];
        this.presentDep = this.deptTree[0];
        this.roleName = "";
        this.$store.dispatch("bpm/listSimpleRoles", "");
      },
      clearDep(arr) {
        this.role.map((e) => (e.flag = false));
        this.deptMangerList.map((e) => (e.flag = false));
        this.userGroup.map((e) => {
          e.children.map((i) => {
            i.flag = false;
          });
        });
        // if (this.typeActive == 0) return;
        if (!arr) return;
        if (arr.children && arr.children.length) {
          for (let index = 0; index < arr.children.length; index++) {
            let item = arr.children[index];
            this.$set(item, "flag", false);
            if (
              (item.children && item.children.length) ||
              (item.userList && item.userList.length)
            ) {
              this.clearDep(item);
            }
          }
        }
        if (arr.userList && arr.userList.length) {
          for (let index = 0; index < arr.userList.length; index++) {
            let item = arr.userList[index];
            this.$set(item, "flag", false);
            if (item.userList && item.userList.length) {
              this.clearDep(item);
            }
          }
        }
      },

      handleClose(done) {
        this.breadcrumb = [];
        done();
      },

    },
  };
</script>

<style lang="scss">
  .initiatingRange {
    border-radius: 20px;

    .main {
      display: flex;
      min-height: 300px;
      max-height: 600px;
      overflow: hidden;

      .left {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex: 0 0 288px;
        min-height: 300px;
        max-height: 600px;
        overflow: auto;

        .head {
          // flex: 0 0 126px;
        }

        .type_list {
          display: flex;
          gap: 28px;
          margin-bottom: 14px;

          .type_item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: #727272;
            cursor: pointer;

            .img {
              width: 28px;
              height: 28px;
              background: #f5f5f7;
              border-radius: 50%;
            }
          }
        }

        .breadcrumb {
          display: flex;
          font-size: 10px;
          margin-bottom: 12px;
          flex-wrap: wrap;
          gap: 10px;

          .breadcrumb_item {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            gap: 10px;

            img {
              width: 10px;
              height: 10px;
            }

            .color {
              color: rgba(114, 114, 114, 0.5);
            }

            .pointer {
              cursor: pointer;
            }
          }
        }

        .tree {
          flex: 1;
          overflow: auto;

          .tree_item {
            padding: 18px 22px;
            display: flex;
            justify-content: space-between;
          }

          .tree_item:hover {
            cursor: pointer;
            background: #f5f5f7;
          }

          .tree_framework {
            .check_box {
              box-sizing: border-box;
              width: 14px;
              height: 14px;
              border-radius: 2px;
              border: 1px solid #060000;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .disabled_box {
              background: #eaeced;
              border: 1px solid #eaeced;
              cursor: not-allowed !important;
              /* 设置鼠标指针为禁止状态 */
            }

            .one {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 18px 22px;
              font-size: 12px;
              color: #060000;

              >div {
                display: flex;
                align-items: center;
                gap: 14px;
              }

              p {
                cursor: pointer;
                color: #3a9b91;
              }

              .level {
                color: #b8b8b8;
                cursor: not-allowed;
              }
            }

            .two {
              display: flex;
              align-items: center;
              padding: 18px 22px;
              font-size: 12px;
              color: #060000;
              gap: 30px;
              color: #727272;

              .userinfo {
                display: flex;
                align-items: center;
                gap: 10px;
              }

              img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
              }
            }
          }

          .bg_avtive {
            background: #f5f5f7;
          }
        }
      }

      .right {
        overflow: auto;
        flex: 1;
        padding: 12px 20px;
        box-sizing: border-box;
        border-left: 1px solid #f3f3f3;

        .list {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .list_item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
              display: flex;
              gap: 14px;
              align-items: center;
              font-size: 12px;
              color: #1b1b1b;

              img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .el-input__inner {
      background: #f5f5f7;
      border-radius: 30px 30px 30px 30px;
      height: 28px;
      width: 248px;
    }

    .el-input__suffix {
      top: -4px;
    }

    .el-button--default {
      color: #060000;
      border-radius: 30px 30px 30px 30px;
      border: 1px solid #060000;
    }

    .el-button--primary {
      border-radius: 30px;
      background: #050000;
      border: 1px solid #050000;
    }

    .el-button--default:hover {
      background: #fff;
    }

    .el-dialog__header {
      border-bottom: 1px solid #f3f3f3;
      text-align: left;

      .el-dialog__headerbtn {
        top: 8px;
        right: 8px;
      }
    }

    .el-dialog__body {
      padding: 0;
      min-height: 300px;
      overflow: hidden;
    }

    .el-dialog__footer {
      border-top: 1px solid #f3f3f3;

      .dialog-footer {
        display: flex;
        justify-content: flex-end;

        .cancel {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .submit {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }


    .el-icon-check:before {
      font-size: 10px;
      width: 100%;
      height: 100%;
      line-height: 14px;
    }

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }

    .circle {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 1px solid #606266;
    }
  }
</style>